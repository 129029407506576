import React, { useEffect } from 'react'
import { Receipt } from 'components'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import * as criteo from 'criteo'
import { RenderedHtml } from 'components'
import { LAYOUT_TYPES, TICKET_TYPES, PASS_TYPES } from '../config'

const propTypes = {
  cart: PropTypes.object.isRequired,
  contactPhone: PropTypes.string.isRequired,
  contactEmail: PropTypes.string.isRequired,
  orderNumber: PropTypes.string.isRequired,
  layoutType: PropTypes.string.isRequired,
  showResellerCheckout: PropTypes.bool,
}

const OrderConfirmation = ({
  cart,
  contactPhone,
  contactEmail,
  orderNumber,
  layoutType,
  personalizedConfirmationDetailsText,
  confirmationContactText,
  showResellerCheckout,
}) => {
  useEffect(() => {
    if (sessionStorage.getItem('purchaseDataSent') !== 'true') {
      sendCriteoData()
      sessionStorage.setItem('purchaseDataSent', true)
    }
  }, [])

  const passCheckoutFlow = layoutType === LAYOUT_TYPES.PASS
  const isSeasonPass = cart.cruisePassType === PASS_TYPES.SEASON_PASS
  const sendCriteoData = () => {
    const adultTickets = cart.lineItems.filter(
      (lineItem) => lineItem.ticketType === TICKET_TYPES.ADULT
    )
    const childTickets = cart.lineItems.filter(
      (lineItem) => lineItem.ticketType === TICKET_TYPES.CHILD
    )
    const adultTicketQuantity =
      adultTickets.length > 0 ? adultTickets[0].quantity : 0
    const childTicketQuantity =
      childTickets.length > 0 ? childTickets[0].quantity : 0

    criteo.salesTag(cart, adultTicketQuantity, childTicketQuantity, orderNumber)
  }

  const locationDetails = [cart.cruiseLocation, cart.cruiseDurationTimeDisplay].filter(v => v).join(' | ')

  return (
    <div>
      <div className="checkout-header">
        <h1>Enjoy Your Cruise!</h1>
      </div>

      <div className="checkout-container">
        <div className="detail-section cruise-details">
          <img src={cart.cruiseImage} alt="" />
          <div className="detail-description-line">
            <p>{cart.cruiseDisplayName.toUpperCase()}</p>
            <p className="line-description">
              {locationDetails}
            </p>
          </div>
        </div>
        {
          isSeasonPass &&
          <div className="detail-section">
            <div className="detail-line">
              <p>Order Number: {orderNumber}</p>
            </div>
          </div>
        }
        {!isSeasonPass && (
          <div className="detail-section">
            <div className="detail-line">
              <p>
                Date:{' '}
                {Moment(cart.eventStartDate).format('dddd, MMMM DD, YYYY')}
              </p>
            </div>
            <div className="detail-line">
              <p>Time: {cart.eventStartTimeDisplay}</p>
            </div>
            {
              cart.cruiseDurationTimeDisplay &&
              <div className="detail-line">
                <p>Duration: {cart.cruiseDurationTimeDisplay}</p>
              </div>
            }
            <div className="detail-line">
              <p>Order Number: {orderNumber}</p>
            </div>
            {!passCheckoutFlow && (
              <div className="detail-line">
                <p>Seating Choice: {cart.cruiseType}</p>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="checkout-container confirmation-contact">
        <div className="detail-section">
          {
            personalizedConfirmationDetailsText
            ?
              <div className="detail-line confirmation-ticket-holder">
                <RenderedHtml>{personalizedConfirmationDetailsText}</RenderedHtml>
              </div>
            :
              <React.Fragment>
                <div className="detail-line confirmation-ticket-info confirmation-ticket-holder">
                  <p>
                    Your {isSeasonPass ? 'season pass has' : 'tickets have'} been sent
                    to: {cart.email}
                  </p>
                </div>

                {!isSeasonPass && (
                  <div className="detail-line">
                    <p>
                      Please arrive <strong>30 minutes</strong> prior to departure. We
                      look forward to welcoming you aboard!
                    </p>
                  </div>
                )}
              </React.Fragment>
          }

          <div className="detail-line">
            {
              confirmationContactText
              ?
                <RenderedHtml>{confirmationContactText}</RenderedHtml>
              :
                <p>
                  If you have any further questions, please contact us directly at{' '}
                  <a href={`tel:${contactPhone}`}>{contactPhone}</a> or by email at{' '}
                  <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                </p>
            }
          </div>
        </div>
      </div>

      <Receipt
        lineItems={cart.lineItems}
        taxes={cart.taxes}
        discounts={cart.discounts}
        couponDetails={cart.couponDetails}
        total={cart.total}
        cruiseDisplayName={cart.cruiseDisplayName}
        passCheckoutFlow={passCheckoutFlow}
        startDateTime={cart.eventStart}
        currentCart={cart}
      />

      {showResellerCheckout && (
        <div className="button-container">
          <a href="/resellers/cruises" className="button-primary">
            Back to Home
          </a>
        </div>
      )}
    </div>
  )
}

OrderConfirmation.propTypes = propTypes

export default OrderConfirmation
