import React, { useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Modal, Spinner } from '@launchpadlab/lp-components'

const propTypes = {
  availableCruises: PropTypes.array.isRequired,
  selectedPass: PropTypes.object.isRequired,
  selectNewPass: PropTypes.func.isRequired,
}

const PassTypeSelector = ({
  availableCruises,
  selectedPass,
  selectNewPass,
}) => {
  const [showModal, setShowModal] = useState(false)
  const selectedCruise = availableCruises.find(cruise => cruise.id === selectedPass.id)

  const handleModal = () => {
    setShowModal(true)
  }

  return (
    <div className="pass-type-section">
      <div className="flex space-between">
        <h2>Pass Type</h2>
        {selectedCruise?.webstoreCruiseDetails?.hiddenFromList !== true && (
          <button className="text-link" onClick={handleModal}>
            Edit
          </button> 
        )}
      </div>
      <div>
        {selectedCruise && (
          <>
            <div className='pass-type-card active'>
              <div className="card-content-wrapper">
                <h3 className={'pass-type-card-headline'}>
                  {selectedCruise.displayName}
                </h3>
                <p className="pass-type-card-body">{selectedCruise.description}</p>
              </div>
            </div>
          </>
        )}
      </div>  
      {showModal && (
        <Modal
          onClose={() => {
            setShowModal(false)
          }}
        >
          <div>
            <h2>Cruise</h2>
            <div className="card-wrapper">
              {availableCruises.filter(cruise => cruise?.webstoreCruiseDetails?.hiddenFromList !== true).map((cruise) => (
                <button
                  type="button"
                  key={cruise.id}
                  onClick={() => {selectNewPass(cruise.id); setShowModal(false)}}
                  className={classnames('pass-type-card grey-background', {
                    active: selectedPass.id === cruise.id,
                  })}
                >
                  <div className="card-content-wrapper">
                    <h3 className={'pass-type-card-headline'}>
                      {cruise.displayName}
                    </h3>
                    {/* <p className="pass-type-card-body">{cruise.description}</p> */}
                  </div>
                </button>
              ))}
            </div>
          </div>
        </Modal>
        )}
    </div>
  )
}

PassTypeSelector.propTypes = propTypes
export default PassTypeSelector
