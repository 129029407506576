import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Spinner } from '@launchpadlab/lp-components'
import MemberModal from './MemberModal'

const propTypes = {
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateMember: PropTypes.func.isRequired,
  removeMember: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

const defaultProps = {}

function Members({ members, updateMember, removeMember, isLoading }) {
  const [memberToEdit, setMemberToEdit] = useState(null)

  return (
    <React.Fragment>
      <div className="member-line-items">
        {members.map((member) => (
          <div key={member.id} className="checkout-container member-line-item">
            <div className="member-line-info">
              <h3>
                {member.firstName} {member.lastName}
              </h3>
              {member.primary && (
                <span className="font-sm">Primary Member</span>
              )}
            </div>
            <div className="member-line-actions">
              <button
                type="button"
                className="link-primary"
                onClick={() => setMemberToEdit(member)}
                aria-label={`Edit ${member.firstName} ${member.lastName}`}
              >
                Edit
              </button>
              <button
                type="button"
                className="remove-icon"
                onClick={() => removeMember(member)}
                aria-label={`Remove ${member.firstName} ${member.lastName}`}
              />
            </div>
          </div>
        ))}
        {isLoading && (
          <div className="member-line-items-spinner">
            <Spinner />
          </div>
        )}
      </div>

      {memberToEdit && (
        <MemberModal
          handleClose={() => setMemberToEdit(null)}
          initialMember={memberToEdit}
          saveMember={updateMember}
          isEditing
        />
      )}
    </React.Fragment>
  )
}

Members.propTypes = propTypes
Members.defaultProps = defaultProps

export default React.memo(Members)
