import React, { useState, useEffect } from 'react'
import { useCheckout } from 'hooks'
import Moment from 'moment-timezone'
import { Modal, Spinner } from '@launchpadlab/lp-components'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import addImage from 'add.svg'
import subtractImage from 'subtract.svg'
import EventTimes from './EventTimes'
import DateInput from './DateInput'
import * as googleTagManager from 'gtm'

const propTypes = {
  hasEvent: PropTypes.bool.isRequired,
  displayName: PropTypes.string,
  location: PropTypes.string,
  date: PropTypes.string,
  displayStartTime: PropTypes.string,
  cruiseType: PropTypes.string,
  durationTimeDisplay: PropTypes.string,
  tickets: PropTypes.array.isRequired,
  maxTickets: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  incrementTicket: PropTypes.func.isRequired,
  decrementTicket: PropTypes.func.isRequired,
  availableCruises: PropTypes.array.isRequired,
  editCruiseDetails: PropTypes.func.isRequired,
  setStandardCruiseTimes: PropTypes.func.isRequired,
  setPremierCruiseTimes: PropTypes.func.isRequired,
  standardCruiseTimes: PropTypes.array,
  premierCruiseTimes: PropTypes.array,
  updateCartCalendarEvent: PropTypes.func,
  webstore: PropTypes.string.isRequired,
  waitingForIncrementOrDecrementResponse: PropTypes.bool.isRequired,
  fetchEventsForDatepickerOnClick: PropTypes.func.isRequired,
  includedDates: PropTypes.array.isRequired,
  initialDatePickerLoading: PropTypes.bool.isRequired,
  changeCruise: PropTypes.func.isRequired,
  editedCruise: PropTypes.object,
  setEditedCruise: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  setSelectedDate: PropTypes.func.isRequired,
  convertDatePickerValue: PropTypes.func.isRequired,
  hasCruiseTimes: PropTypes.bool.isRequired,
  fetchEventsForDatepickerOnMonthChange: PropTypes.func.isRequired,
  datePickerLoading: PropTypes.bool.isRequired,
  currentCart: PropTypes.object.isRequired,
}

const CruiseDetails = ({
  hasEvent,
  displayName,
  location,
  date,
  displayStartTime,
  cruiseType,
  durationTimeDisplay,
  tickets,
  maxTickets,
  icon,
  ticketQuantities,
  incrementTicket,
  decrementTicket,
  availableCruises,
  editCruiseDetails,
  setStandardCruiseTimes,
  setPremierCruiseTimes,
  standardCruiseTimes,
  premierCruiseTimes,
  updateCartCalendarEvent,
  webstore,
  waitingForIncrementOrDecrementResponse,
  fetchEventsForDatepickerOnClick,
  includedDates,
  initialDatePickerLoading,
  changeCruise,
  editedCruise,
  setEditedCruise,
  selectedDate,
  setSelectedDate,
  convertDatePickerValue,
  hasCruiseTimes,
  fetchEventsForDatepickerOnMonthChange,
  datePickerLoading,
  currentCart
}) => {

  const { webstoreName } = useCheckout()
  const [cruiseButtonActive, setCruiseButtonActive] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [incrementDisabled, setIncrementDisabled] = useState(false)

  useEffect(() => {
    if (editedCruise.startDate.length > 0) {
      editCruiseDetails('cruiseDetails',editedCruise.eventTypeId, editedCruise.startDate)
    }
  }, [editedCruise.startDate])

  useEffect(() => {
    const ticketValues = Object.values(ticketQuantities) || []
    const totalTicketCount = ticketValues.reduce((a, b) => a + b, 0)
    totalTicketCount >= maxTickets || waitingForIncrementOrDecrementResponse
      ? setIncrementDisabled(true)
      : setIncrementDisabled(false)
  }, [ticketQuantities])

  const handleModal = () => {
    setShowModal(true)
  }

  function decrementDisabled(ticketId) {
    return ticketQuantities[ticketId] > 0 &&
      !waitingForIncrementOrDecrementResponse
      ? false
      : true
  }

  const locationDetails = [location, durationTimeDisplay].filter(v => v).join(' | ')

  return (
    <div>
      <h2>Cruise Details</h2>
      <div className="checkout-container">
        <div className="detail-section cruise-details">
          <img src={icon} alt="" />
          <div className="detail-description-line">
            <h3 bablic-exclude="true">{displayName.toUpperCase()}</h3>
            <p className="line-description">
              { locationDetails }
            </p>
          </div>
          <button className="text-link" onClick={handleModal}>
            Edit
          </button>
        </div>
        {hasEvent ? (
          <div className="detail-section">
            <div className="detail-line">
              <h3>
                Date: {date && Moment(date).format('dddd, MMMM DD, YYYY')}
              </h3>
            </div>
            <div className="detail-line">
              <h3>Time: {displayStartTime}</h3>
            </div>
            <div className="detail-line">
              <h3>
                Seating Choice: {cruiseType}
                {/*<img src="/assets/tooltip.svg" />*/}
              </h3>
            </div>
          </div>
        ) : (
          <div className="detail-section no-events">
            <h3>
              No events{' '}
              {date && `for ${Moment(date).format('dddd, MMMM DD, YYYY')}`}
            </h3>
          </div>
        )}
        <div className="detail-section">
          {tickets.map((ticket, index) => (
            <div key={index} className="ticket-section">
              <div className="detail-description-line">
                <h3>{ticket.displayName}</h3>
                <p className="line-description">{ticket.priceDescription}</p>
              </div>
              <div className="ticket-picker">
                <input
                  type="image"
                  name={`decrement-${ticket.id}`}
                  onClick={() => decrementTicket(ticket)}
                  disabled={decrementDisabled(ticket.id)}
                  src={subtractImage}
                  alt={`Remove one ${ticket.displayName} ticket`}
                />
                <div className="ticket-number">
                  <p>{ticketQuantities[ticket.id] || 0}</p>
                </div>
                <input
                  type="image"
                  name={`increment-${ticket.id}`}
                  onClick={() => {
                    incrementTicket(ticket)
                    googleTagManager.sendAddToCartData(
                      ticket,
                      webstoreName,
                      currentCart
                    )
                  }}
                  disabled={incrementDisabled}
                  src={addImage}
                  alt={`Add one ${ticket.displayName} ticket`}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      {showModal && (
        <Modal
          onClose={() => {
            setShowModal(false)
            setEditedCruise({ eventTypeId: '', startDate: '' })
            setStandardCruiseTimes([])
            setPremierCruiseTimes([])
            setSelectedDate(null)
            setCruiseButtonActive(0)
          }}
        >
          <div>
            <h2>Cruise</h2>
            {availableCruises.map((cruise) => (
              <button
                key={cruise.id}
                value={cruise.eventTypeId}
                onClick={() => {
                  setCruiseButtonActive(parseInt(cruise.eventTypeId))
                  changeCruise(cruise.eventTypeId)
                  fetchEventsForDatepickerOnClick(cruise)
                }}
                className={classnames('cruise-button', {
                  active: cruiseButtonActive === cruise.eventTypeId,
                })}
                bablic-exclude="true"
              >
                <img src={cruise.image} className="cruise-button-logo" alt="" />
                {cruise.displayName}
              </button>
            ))}
            {!!editedCruise.eventTypeId && (
              <div className="modal-section">
                <h2>Date</h2>
                {initialDatePickerLoading ? (
                  <Spinner />
                ) : (
                  <DatePicker
                    placeholderText="Select a date"
                    dateFormat="yyyy-MM-dd"
                    selected={selectedDate}
                    onChange={(date) => convertDatePickerValue(date)}
                    minDate={new Date()}
                    includeDates={includedDates}
                    popperPlacement="top"
                    customInput={<DateInput />}
                    onMonthChange={(lastDay) => {
                      fetchEventsForDatepickerOnMonthChange(lastDay)
                    }}
                    calendarClassName={classnames('datepicker', {
                      'in-progress': datePickerLoading,
                    })}
                    className="datepicker-input"
                  />
                )}
              </div>
            )}
            {hasCruiseTimes && (
              <div className="cruise-times">
                <h2 className="event-times-header">Times</h2>
                {premierCruiseTimes.length > 0 && (
                  <EventTimes
                    subHeader={'Premier Seating'}
                    eventTimes={premierCruiseTimes}
                    updateCartCalendarEvent={updateCartCalendarEvent}
                    setShowModal={setShowModal}
                    setEditedCruise={setEditedCruise}
                    setStandardCruiseTimes={setStandardCruiseTimes}
                    setPremierCruiseTimes={setPremierCruiseTimes}
                    setSelectedDate={setSelectedDate}
                    setCruiseButtonActive={setCruiseButtonActive}
                  />
                )}
                {standardCruiseTimes.length > 0 && (
                  <EventTimes
                    subHeader={
                      premierCruiseTimes.length > 0 ? 'Standard Seating' : ''
                    }
                    eventTimes={standardCruiseTimes}
                    updateCartCalendarEvent={updateCartCalendarEvent}
                    setShowModal={setShowModal}
                    setEditedCruise={setEditedCruise}
                    setStandardCruiseTimes={setStandardCruiseTimes}
                    setPremierCruiseTimes={setPremierCruiseTimes}
                    setSelectedDate={setSelectedDate}
                    setCruiseButtonActive={setCruiseButtonActive}
                  />
                )}
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  )
}

CruiseDetails.propTypes = propTypes
export default CruiseDetails
