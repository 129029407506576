import React from 'react' 
import PropTypes from 'prop-types'
import {
    Checkout,
    SeasonPassRedemption,
    CheckoutMultiPage,
  } from 'components'
  import { LAYOUT_TYPES } from 'config'
import { CheckoutProvider } from 'contexts'
import { NinetailedProvider } from '@ninetailed/experience.js-react';

const propTypes = {
    webstore: PropTypes.string.isRequired,
    webstoreName: PropTypes.string.isRequired,
    eventTypeId: PropTypes.number,
    eventId: PropTypes.number,
    startDate: PropTypes.string.isRequired,
    cart: PropTypes.object.isRequired,
    availableCruises: PropTypes.array.isRequired,
    termsLink: PropTypes.string,
    warningText: PropTypes.string,
    layoutType: PropTypes.string.isRequired,
    user: PropTypes.object,
    enableWallet: PropTypes.bool,
    abandonedCartEnabled: PropTypes.bool,
  }

const defaultProps = {user: {}}


const ShoppingCart = (props) => {

    const getNinetailedProfileId = (profileId) => {
      if (profileId) {
        return profileId
      }

      if (typeof window === 'undefined') 
        return undefined;

      const params = new URLSearchParams(window.location.search); 
      const queryParamsObj = {};
      for (const [key, value] of params.entries()) {
        queryParamsObj[key] = value; 
      }

      return queryParamsObj?.profileId;
    } 

    return (
      <CheckoutProvider value={props}>
        <NinetailedProvider
          clientId="0992e7fc-b45e-4c48-999d-1b6cc94bc601"
          environment="development" 
          onInitProfileId={getNinetailedProfileId}
        >
          <div>
              {props.layoutType === LAYOUT_TYPES.REDEMPTION ?
                  <SeasonPassRedemption />
                :
                  props.enableWallet ? <CheckoutMultiPage /> : <Checkout />
              }

          </div>
        </NinetailedProvider>
      </CheckoutProvider>
    )
  }


ShoppingCart.propTypes = propTypes
ShoppingCart.defaultProps = defaultProps
export default ShoppingCart
