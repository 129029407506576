import React from 'react'
import pluralize from 'pluralize'
import moment from 'moment-timezone'
import { TICKET_TYPES } from 'config'
import PropTypes from 'prop-types'

const propTypes = {
  lineItems: PropTypes.array,
  taxes: PropTypes.number,
  discounts: PropTypes.number,
  couponDetails: PropTypes.string,
  total: PropTypes.number,
  cruiseDisplayName: PropTypes.string,
  passCheckoutFlow: PropTypes.bool,
  resellerCheckoutFlow: PropTypes.bool,
  startDateTime: PropTypes.string,
  currentCart: PropTypes.object,
  showResellerCheckout: PropTypes.bool,
  showBackButton: PropTypes.bool,
  handleBackButtonClick: PropTypes.func,
}

const defaultProps = {
  passCheckoutFlow: false,
  resellerCheckoutFlow: false,
}

function displayCurrency(number) {
  if (number >= 0) return `$${Number(number).toFixed(2)}`
}

function capitalize(string) {
  return string.trim().replace(/^\w/, (char) => char.toUpperCase())
}

function ticketDescription(webProductDisplayName, cruiseDisplayName) {
  return webProductDisplayName === TICKET_TYPES.PASS
    ? cruiseDisplayName
    : `${webProductDisplayName} ${cruiseDisplayName}`
}

pluralize.addUncountableRule('VIP')
pluralize.addUncountableRule('Standard')

const Receipt = ({
  lineItems,
  taxes,
  discounts,
  couponDetails,
  removeCoupon,
  total,
  cruiseDisplayName,
  passCheckoutFlow,
  resellerCheckoutFlow,
  startDateTime,
  currentCart,
  showResellerCheckout,
  showBackButton,
  handleBackButtonClick
}) => {
  function displaySquareLineItem(squareLineItem) {
    return (
      <div className="detail-description-line">
          <h3>  
            {pluralize(squareLineItem.itemDescription, squareLineItem.quantity, true)} x{' '}
            {displayCurrency(squareLineItem.itemPrice)}
          </h3>
      </div>
    )
  }

  function ticketDetails(lineItem) {
    if (passCheckoutFlow && cruiseDisplayName !== 'The Beast') {
      return (
        <div className="detail-description-line">
          <h3>
            {pluralize(
              ticketDescription(
                lineItem.webProductDisplayName,
                cruiseDisplayName
              ),
              lineItem.quantity,
              true
            )}{' '}
            x {displayCurrency(lineItem.sellPrice)}
          </h3>
          {startDateTime && (
            <p className="line-description">
              {moment(startDateTime).utc().format('MMMM D, YYYY | h:mm a')}
            </p>
          )}
        </div>
      )
    } else {
      return (
        <div className="detail-description-line">
          <h3>
            {pluralize(lineItem.displayName, lineItem.quantity, true)} x{' '}
            {displayCurrency(lineItem.sellPrice)}
          </h3>
          { !lineItem.fee && <p className="line-description">{capitalize(lineItem.cruiseType)}</p> }
        </div>
      )
    }
  }

  return (
    <React.Fragment>
      <div className="receipt flex space-between">
        <h2>Purchase Summary</h2>
        {showBackButton &&  
            <button 
                className="text-link"
                onClick={() => handleBackButtonClick(1)}
            >
                Back
            </button> 
        }
      </div>
      <div className="checkout-container receipt">
        {showBackButton && currentCart && (
          <>
            <div className="detail-section">
                <h3 bablic-exclude="true">{currentCart.eventName ?  currentCart.eventName?.toUpperCase() : currentCart.cruiseDisplayName.toUpperCase()}</h3>
                <p className="line-description">
                  { [currentCart.cruiseLocation, currentCart.cruiseDurationTimeDisplay].filter(v => v).join(' | ') }
                </p>
                {currentCart.cruisePassType !== "season_pass" && (
                  <>
                    <div className="detail-line">
                      <h3>
                        Date: {currentCart.eventStart && moment.utc(currentCart.eventStart).format('dddd, MMMM DD, YYYY')}
                      </h3>
                    </div>
                    <div className="detail-line">
                      <h3>Time: {currentCart.eventStart && moment.utc(currentCart.eventStart).format('h:mm A')}</h3>
                    </div>
                  </>
                )}
            </div>
          </>
        )}
        {lineItems.map((lineItem) => (
          <div key={lineItem.webProductId} className="detail-section flex-row">
            <div className="detail-description-line">
              {ticketDetails(lineItem)}
            </div>
            <p className="subtotal">{displayCurrency(lineItem.subtotalAfterCustomerPricingDiscounts)}</p>
          </div>
        ))}
        {currentCart?.squareLineItems.map((squareLineItem) => (
          <div key={squareLineItem.itemId} className="detail-section flex-row">
            <div className="detail-description-line">
              {displaySquareLineItem(squareLineItem)}
            </div>
            <p className="subtotal">{displayCurrency(squareLineItem.itemPrice * squareLineItem.quantity)}</p>
          </div>
        ))}
        <div className="detail-section">
          {discounts > 0 && (
            <React.Fragment>
              {couponDetails && (
                <div className="detail-line applied-coupon">
                  <h3 className="coupon-details">{couponDetails}</h3>
                  {removeCoupon && (
                    <button
                      type="button"
                      onClick={removeCoupon}
                      className="remove-coupon text-link"
                    >
                      Remove
                    </button>
                  )}
                </div>
              )}
              <div className="detail-line">
                <h3>Discounts</h3>
                <p>{displayCurrency(discounts)}</p>
              </div>
            </React.Fragment>
          )}
          {!passCheckoutFlow && (
            <div className="detail-line">
              <h3>State / Local Tax</h3>
              <p>{displayCurrency(taxes)}</p>
            </div>
          )}
          <div className="detail-line">
            <h3>Total</h3>
            <p>{displayCurrency(total)}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

Receipt.propTypes = propTypes
Receipt.defaultProps = defaultProps

export default Receipt
