import React, { useState, useEffect, useCallback } from 'react'
import { useCheckout }  from 'hooks'
import PropTypes from 'prop-types'
import { Spinner } from '@launchpadlab/lp-components'
import * as googleTagManager from 'gtm'
import * as api from 'api'

const propTypes = {
  validateRequiredData: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  pendingRequest: PropTypes.bool.isRequired,
  currentCart: PropTypes.object.isRequired,
}

const WalletForm = ({
  handleError,
  validateRequiredData,
  pendingRequest,
  currentCart,
  walletType
}) => {

  const {
    webstore,
  } = useCheckout();

  const [sessionKey, setSessionKey] = useState('')
  const [iframeHtml, setIframeHtml] = useState('')

  useEffect(() => {
    setSessionKey('')
  }, [walletType])

  const handleIframeSubmit = useCallback(
    (e) => {
      if (sessionKey) {
        const message = e.data
        const data = message.data
        switch (message.type) {
          case 1:
            data.errors
            const hasSessionKeyError = data?.errors?.filter((err) =>
              err.message.toLowerCase().includes("'sessionkey' is invalid") ||
              err.messageCode == "unknownError"
            )

            if (data.errors === undefined || hasSessionKeyError?.length > 0) {
              // if there is a sessionKey invalid error, reset sessionkey
              // to force payment form to reload with new iframe and sessionKey
              setSessionKey('')
            } else {
              handleError('Payment Processing Error', data.errors[0].message)
            }
            break
          case 2:
            const frameId = walletType === 'google' ? 'hpc--googlepay-frame' : walletType === 'apple' ? 'hpc--applepay-frame' : null;
            if (frameId) {
                const frameElement = document.getElementById(frameId);
                if (frameElement) {
                  frameElement.width = '100%';
                  frameElement.height = data.height;
                }
            }
            break
          case 3:
            data.attributes
            const paymentKey = data.paymentKeys[0]
            validateRequiredData(paymentKey, data.attributes, sessionKey, walletType)
            break
          case 4:
            data.message
            if (
              data.isValid &&
              sessionStorage.getItem('checkoutDataSent') !== 'true'
            ) {
              googleTagManager.sendCheckoutData(currentCart)
              sessionStorage.setItem('checkoutDataSent', true)
            }
            break
        }
      }
    },
    [sessionKey, walletType]
  )

  useEffect(() => {
    if (!sessionKey && walletType == 'google') {
      api.fetchGoogleWalletIframe({webstore, merchantAccountId: currentCart.merchantAccountId,cartId: currentCart.id}).then((response) => {
         setIframeHtml(response.html)
         setSessionKey(response.sessionKey)
      })
    } else if (!sessionKey && walletType == 'apple') {
        api.fetchApplePayIframe({webstore, merchantAccountId: currentCart.merchantAccountId,cartId: currentCart.id}).then((response) => {
           setIframeHtml(response.html)
           setSessionKey(response.sessionKey)
        })
    } 
  }, [sessionKey])

  useEffect(() => {
    window.addEventListener('message', handleIframeSubmit)
    return () => window.removeEventListener('message', handleIframeSubmit)
  }, [handleIframeSubmit])

  if (!iframeHtml) return <Spinner />

  return (
    <div>
      <div
        id="payment-section"
        className={
          pendingRequest
            ? 'pending-request-spinner '
            : 'payment-section-digital-wallet'
        }
      >
       {pendingRequest ? (
          <Spinner />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: iframeHtml }} />
        )} 
      </div>
    </div>
  )
}

WalletForm.propTypes = propTypes

export default WalletForm
