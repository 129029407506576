import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { Input } from 'components';
import { Button } from '@launchpadlab/lp-components';
import { passValidation } from 'utils';
import {
  handleError,
} from 'utils';
import * as api from 'api';

const propTypes = {
  webstore: PropTypes.string.isRequired,
  setPassDetails: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  setIsValid: PropTypes.func.isRequired,
  updateCartInformation: PropTypes.func.isRequired,
}


const RedemptionForm = ({webstore, setPassDetails, isValid, setIsValid, updateCartInformation}) => {

  const Schema = Yup.object().shape({
      barcode: Yup.string().trim().test("validation-barcode", function(barcode){
        const validation = passValidation(barcode);
        if(!validation.isValid){
          return this.createError({
            path: this.path,
            message: validation.errorMessage,
          });
        }
        return true
      }),
  })

  const handleFormSubmission = async (values) => {
    try {
      const response = await api.queryTicket(webstore, values.barcode);
      setPassDetails(response);
      setIsValid(true);
      const data = {
        first_name: response.firstName,
        last_name: response.lastName,
        email: response.email,
        phone: response.phone || 8888888888,
        postal_code: response.zip,
        passAcct: response.passAcct,
        passId: response.passId,
        season_pass_barcode: response.visualID
      }
      updateCartInformation(data);
    } catch(e) {
      console.error(e);
      setIsValid(false);
      setPassDetails();
      handleError('Oops!', 'Not Valid SeasonPass.');
    }
  }

  return (
    <div className="redemption-form">
        <div className="redemption-text-section">
          <p>This site is for Season Pass Redemption.</p>
          <p>Please enter your Season Pass barcode numbers below.</p>
          <p>After entering the numbers, please click “VERIFY” and then “CONTINUE” to choose your cruise</p>
        </div>
        <Formik initialValues={{barcode: ''}} validationSchema={Schema}>
          {({ values, handleChange, handleBlur, errors, touched }) => (
            <form id={"season-pass-form"}>
              <div className="season-pass-container">
                <div className="input-field">
                  <Input
                    required
                    label="Season Pass Barcode"
                    name={"barcode"}
                    pattern=".*\S+.*"
                  />
                </div>
                <div className="time-buttons-wrapper">
                  <Button
                    style="primary"
                    className={`button-primary submit-order-button ${isValid ? 'verify-button' : ''}`}
                    onClick={() => handleFormSubmission(values)}
                  >
                    Verify
                  </Button>
                </div>
              </div>
            </form>
          )} 
        </Formik>
    </div>
  )
}


RedemptionForm.propTypes = propTypes;
export default RedemptionForm;