import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { LAYOUT_TYPES, TICKET_TYPES } from 'config'
import * as criteo from 'criteo'
import moment from 'moment-timezone'
import { TimedTicketPassFlow, TimedTicketCruiseFlow, TimedTicketResellerFlow } from 'components'
import * as api from 'api'


const isCoupleTicket = ticket => {
  if(ticket.displayName){
    return ticket.displayName.indexOf('Couple') !== -1
  }
  return false
}

const propTypes = {
  layoutType: PropTypes.string.isRequired,
  webstore: PropTypes.string.isRequired,
  currentCart: PropTypes.object,
  setCurrentCart: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  updateCustomerInformation: PropTypes.func.isRequired,
  updateCardHolderInformation: PropTypes.func.isRequired,
  availableCruises: PropTypes.array.isRequired,
  updateCartCoupon: PropTypes.func.isRequired,
  validateCoupon: PropTypes.func.isRequired,
  eventId: PropTypes.number,
  startDate: PropTypes.string.isRequired,
  eventTypeId: PropTypes.number.isRequired,
  selectedPass: PropTypes.object.isRequired,
  showResellerCheckout: PropTypes.bool,
  setShowResellerCheckout: PropTypes.func,
  validateRequiredData: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  pendingRequest: PropTypes.bool.isRequired,
  setPendingRequest: PropTypes.func,
  termsLink: PropTypes.string,
  hasItems: PropTypes.bool,
  selectNewPass: PropTypes.func,
  setFullAddress: PropTypes.func.isRequired,
  isSinglePage: PropTypes.bool.isRequired,
  upSellItems: PropTypes.array.isRequired,
  setUpSellItems: PropTypes.func.isRequired,
}

const defaultProps = {}

function TimedTicket({
  layoutType,
  webstore,
  currentCart,
  setCurrentCart,
  customer,
  updateCustomerInformation,
  updateCardHolderInformation,
  availableCruises,
  updateCartCoupon,
  validateCoupon,
  eventId,
  startDate,
  eventTypeId,
  selectedPass,
  showResellerCheckout,
  setShowResellerCheckout,
  validateRequiredData,
  handleError,
  pendingRequest,
  setPendingRequest,
  termsLink,
  hasItems,
  emailField,
  selectNewPass,
  setFullAddress,
  isSinglePage,
  upSellItems,
  setUpSellItems
}) {
  const passCheckoutFlow = layoutType === LAYOUT_TYPES.PASS
  const cruiseCheckoutFlow = layoutType === LAYOUT_TYPES.CRUISE
  const resellerCheckoutFlow = layoutType === LAYOUT_TYPES.RESELLER
  const { token: cartToken } = currentCart

  // STATE
  const [event, setEvent] = useState(null)
  const [editedCruise, setEditedCruise] = useState({
    eventTypeId: '',
    startDate: '',
  })

  const [standardCruiseTimes, setStandardCruiseTimes] = useState([])
  const [premierCruiseTimes, setPremierCruiseTimes] = useState([])
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedEventId, setSelectedEventId] = useState(0);
  const [
    waitingForIncrementOrDecrementResponse,
    setWaitingForIncrementOrDecrementResponse,
  ] = useState(false)
  const [initialDatePickerLoading, setInitialDatePickerLoading] =
    useState(false)
  const [includedDates, setIncludedDates] = useState([])

  const hasCruiseTimes =
    !!standardCruiseTimes.length || !!premierCruiseTimes.length
  const [datePickerLoading, setDatePickerLoading] = useState(false)

  // EFFECTS
  useEffect(() => {
    // currentCart should already be updated with requested
    // or existing eventId and eventTypeId values on page load.

    // note: if incoming startDate from marketing site is different
    // than an existing eventId and eventStart already saved to cart,
    // the requested date takes precedence, and old eventId will be
    // cleared and replaced with eventId and data for requested startDate

    // first check cart for an eventId and set that as the starting event.
    // if no eventId, request events using eventTypeId based on
    // eventTypeId set on the currentCart. if a start date is present in
    // the url param props, send that in the eventTypeId events request
    const currentEventId = currentCart.eventId || eventId;
    const currentEventTypeId = currentCart.eventTypeId || eventTypeId;
    if(currentCart.eventStartDate){
      setSelectedDate(new Date(currentCart.eventStartDate + 'T07:00:00Z')); 
    }
    if(currentCart.eventId && webstore === "circle_line"){
      api
      .fetchEvent({ webstore, eventId: currentEventId })
      .then((response) => {
        setEvent(response)
        if (currentCart.eventName !== response.eventName) {
          // we only need to send event details to cart if eventId from cart
          // came from marketing site eventId rather than refresh of page
          // for an existing cart with the same eventId. if refresh, event
          // details would have already been saved to cart, so we can skip
          // the extra update request
          updateCartInitialEvent(response)
        }
      })
      .catch((error) => console.log(error))
    } else if (currentCart.eventId && webstore !== "circle_line") {
      setSelectedEventId(currentCart.eventId);
      api
        .fetchEvents({ 
          webstore, 
          eventTypeId : currentEventTypeId,
          ...(currentCart.eventStartDate && {startDate: currentCart.eventStartDate}),
          includePricing: true, 
        })
        .then((response) => {
          const event = response.find(item => item.id === currentEventId);
          setEvent(event);
          const standardCruises = response.filter(
            (cruise) => !cruise.premierCruise
          )
          const premierCruises = response.filter((cruise) => cruise.premierCruise)
          setStandardCruiseTimes(standardCruises)
          setPremierCruiseTimes(premierCruises)
          if (currentCart.eventName !== response.eventName) {
            updateCartInitialEvent(event)
          }
        })
        .catch((error) => console.log(error))
    } else {
      api
        .fetchEvents({ 
          webstore, 
          eventTypeId : currentEventTypeId, 
          startDate 
        })
        .then((response) => {
          setEvent(response[0])
          updateCartInitialEvent(response[0])
        })
        .catch((error) => console.log(error))
    }
  }, [])

  // NYCLWEB-25
  useEffect(() => {
    setEvent(null)
  }, [selectedPass])

  // CRUISE
  const changeCruise = (eventTypeId) => {
    setEditedCruise({
      ...editedCruise,
      eventTypeId: eventTypeId,
      startDate: '',
    })
    setSelectedDate(null)
    setSelectedEventId(0);
    setStandardCruiseTimes([])
    setPremierCruiseTimes([])
  }

  // CART
  const updateCartInitialEvent = (selectedEvent) => {
    // api call to update cart with event from url params
    // or existing cart event (based on eventId set during page load)
    if (!selectedEvent) return

    api
      .updateCart({
        webstore,
        cartToken,
        cruiseId: selectedEvent.cruiseId,
        eventTypeId: selectedEvent.eventTypeId,
        eventId: selectedEvent.id,
        resourceId: selectedEvent.resourceId,
        eventName: selectedEvent.eventName,
        eventStart: selectedEvent.startDateTime,
        eventEnd: selectedEvent.endDateTime,
      })
      .then((response) => setCurrentCart(response))
      .catch((error) => console.log(error))
  }

  // TICKETS
  const incrementTicket = (ticket) => {
    setWaitingForIncrementOrDecrementResponse(true)
    const quantity=  isCoupleTicket(ticket) ? 2 : undefined
    ticket= {
      ...ticket,
      ...(quantity && { quantity: quantity })
    }
    api
      .incrementTicket({
        webstore,
        cartToken,
        ticket
      })
      .then((response) => {
        setCurrentCart(response)
        sendCriteoData(response)
      })
      .catch((error) => console.log(error))
      .finally(() => setWaitingForIncrementOrDecrementResponse(false))
  }

  const decrementTicket = (ticket) => {
    setWaitingForIncrementOrDecrementResponse(true)
    const quantity= isCoupleTicket(ticket) ? 2 : undefined
    api
      .decrementTicket({
        webstore,
        cartToken,
        webProductId: ticket.id,
        ...(quantity && { quantity: quantity })
      })
      .then((response) => {
        setCurrentCart(response)
        sendCriteoData(response)
      })
      .catch((error) => console.log(error))
      .finally(() => setWaitingForIncrementOrDecrementResponse(false))
  }

  const sendCriteoData = (data) => {
    const adultTickets = data.lineItems.filter(
      (lineItem) => lineItem.ticketType === TICKET_TYPES.ADULT
    )
    const childTickets = data.lineItems.filter(
      (lineItem) => lineItem.ticketType === TICKET_TYPES.CHILD
    )
    const adultTicketQuantity =
      adultTickets.length > 0 ? adultTickets[0].quantity : 0
    const childTicketQuantity =
      childTickets.length > 0 ? childTickets[0].quantity : 0
    criteo.basketTag(data, adultTicketQuantity, childTicketQuantity)
  }

  // DATE/TIME
  const updateCartCalendarEvent = (selectedEvent) => {
    // an event selected from calendar needs to be requested
    // individually to retreive ticket/pricing data that
    // is not part of the bulk calendar events call.
    // (pricing is initially left out to speed up api call
    // for multi date request)

    api
      .updateCart({
        webstore,
        cartToken,
        cruiseId: selectedEvent.cruiseId,
        eventTypeId: selectedEvent.eventTypeId,
        eventId: selectedEvent.id,
        resourceId: selectedEvent.resourceId,
        eventName: selectedEvent.eventName,
        eventStart: selectedEvent.startDateTime,
        eventEnd: selectedEvent.endDateTime,
      })
      .then((response) => {
        setCurrentCart(response)
        return api.fetchEvent({ webstore, eventId: selectedEvent.id })
      })
      .then((response) => { setEvent(response); setSelectedEventId(selectedEvent.id) })
      .catch((error) => console.log(error))
  }

  const convertDatePickerValue = (date) => {
    setSelectedDate(date)
    const momentObject = moment(date).toDate()
    const formattedDate = moment(momentObject).format('YYYY-MM-DD')
    setEditedCruise({ ...editedCruise, startDate: formattedDate })
  }

  const fetchEventsForDatepickerOnClick = ({
    eventTypeId,
    defaultStartDate,
  }) => {
    // start calendar event fetching with selected cruise's defaultStartDate.
    // it should return the first future date that the cruise will begin running,
    // else returns the current date. the default start date is set
    // in backend by daily eGalaxy data syncs. this prevents cruises with
    // start dates much further in the future from having no selectable/editable
    // dates when calendar is opened
    const momentObjStart = defaultStartDate
      ? moment(defaultStartDate).tz('America/New_York')
      : moment().tz('America/New_York')
    const startDate = momentObjStart.format('YYYY-MM-DD')
    const endDate = momentObjStart.add(35, 'd').format('YYYY-MM-DD')
    setInitialDatePickerLoading(true)
    api
      .fetchEvents({
        webstore,
        eventTypeId,
        startDate,
        endDate,
        includePricing: false,
      })
      .then((response) => {
        const eventDates = response.map(
          (eventDate) => new Date(eventDate.startDateTime)
        )
        const uniqDatesWithEvents = [...new Set(eventDates)]
        setIncludedDates(uniqDatesWithEvents)
        setInitialDatePickerLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const fetchEventsForDatepickerOnMonthChange = (lastDay) => {
    const eventTypeId = editedCruise.eventTypeId
    const firstDay = new Date(lastDay.getFullYear(), lastDay.getMonth(), -2) //changed from -1 to -2 so the calendar back arrow works for La Barca (it wasn't appearing for June).
    const momentFirstDay = moment(firstDay)
    const momentLastDay = moment(lastDay)
    const startDate = momentFirstDay.format('YYYY-MM-DD')
    const endDate = momentLastDay.add(35, 'd').format('YYYY-MM-DD')
    setDatePickerLoading(true)
    api
      .fetchEvents({
        webstore,
        eventTypeId,
        startDate,
        endDate,
        includePricing: false,
      })
      .then((response) => {
        const eventDates = response.map(
          (eventDate) => new Date(eventDate.startDateTime)
        )
        const uniqDatesWithEvents = [...new Set(eventDates)]
        setIncludedDates(uniqDatesWithEvents)
        setDatePickerLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const editCruiseDetails = (type, eventTypeId, selectedDate) => {
    api
      .fetchEvents({
        webstore,
        eventTypeId,
        startDate: selectedDate,
        includePricing: webstore !== 'circle_line' ? 'true' : 'false',
      })
      .then((response) => {
        const standardCruises = response.filter(
          (cruise) => !cruise.premierCruise
        )
        const premierCruises = response.filter((cruise) => cruise.premierCruise)
        setStandardCruiseTimes(standardCruises)
        setPremierCruiseTimes(premierCruises)
      })
      .catch((error) => console.log(error))
  }

  if (passCheckoutFlow) {
    return (
      <TimedTicketPassFlow
        {...{
          event,
          fetchEventsForDatepickerOnClick,
          webstore,
          currentCart,
          setCurrentCart,
          updateCartCoupon,
          validateCoupon,
          customer,
          updateCustomerInformation,
          editCruiseDetails,
          editedCruise,
          changeCruise,
          includedDates,
          initialDatePickerLoading,
          convertDatePickerValue,
          hasCruiseTimes,
          standardCruiseTimes,
          premierCruiseTimes,
          selectedEventId,
          updateCartCalendarEvent,
          selectedDate,
          incrementTicket,
          decrementTicket,
          waitingForIncrementOrDecrementResponse,
          fetchEventsForDatepickerOnMonthChange,
          datePickerLoading,
          selectedPass,
          setFullAddress,
          isSinglePage,
          upSellItems,
          setUpSellItems
        }}
      />
    )
  }

  if (cruiseCheckoutFlow) {
    return (
      <TimedTicketCruiseFlow
        {...{
          event,
          customer,
          updateCustomerInformation,
          currentCart,
          startDate,
          validateCoupon,
          updateCartCoupon,
          incrementTicket,
          decrementTicket,
          availableCruises,
          editCruiseDetails,
          setStandardCruiseTimes,
          setPremierCruiseTimes,
          standardCruiseTimes,
          premierCruiseTimes,
          updateCartCalendarEvent,
          webstore,
          waitingForIncrementOrDecrementResponse,
          fetchEventsForDatepickerOnClick,
          includedDates,
          initialDatePickerLoading,
          changeCruise,
          editedCruise,
          setEditedCruise,
          selectedDate,
          setSelectedDate,
          convertDatePickerValue,
          hasCruiseTimes,
          fetchEventsForDatepickerOnMonthChange,
          datePickerLoading,
          setFullAddress,
          isSinglePage,
          upSellItems,
          setUpSellItems,
          setCurrentCart
        }}
      />
    )
  }

  if (resellerCheckoutFlow) {
    return (
      <TimedTicketResellerFlow
        {...{
          event,
          fetchEventsForDatepickerOnClick,
          webstore,
          currentCart,
          setCurrentCart,
          customer,
          updateCustomerInformation,
          updateCardHolderInformation,
          editCruiseDetails,
          editedCruise,
          changeCruise,
          includedDates,
          initialDatePickerLoading,
          convertDatePickerValue,
          hasCruiseTimes,
          standardCruiseTimes,
          premierCruiseTimes,
          updateCartCalendarEvent,
          incrementTicket,
          decrementTicket,
          waitingForIncrementOrDecrementResponse,
          fetchEventsForDatepickerOnMonthChange,
          datePickerLoading,
          selectedPass,
          availableCruises,
          selectedDate,
          showResellerCheckout,
          setShowResellerCheckout,
          handleError,
          setPendingRequest,
          pendingRequest,
          termsLink,
          validateCoupon,
          updateCartCoupon,
          hasItems,
          validateRequiredData,
          emailField,
          selectNewPass,
          setFullAddress,
          selectedEventId,
          isSinglePage,
          upSellItems,
          setUpSellItems
        }}
      />
    )
  }
}

TimedTicket.propTypes = propTypes
TimedTicket.defaultProps = defaultProps

export default React.memo(TimedTicket)
