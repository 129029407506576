import React, { useState, useEffect } from 'react'
import { useCheckout } from 'hooks'
import PropTypes from 'prop-types'
import addImage from 'add.svg'
import subtractImage from 'subtract.svg'
import * as api from 'api'

const propTypes = {
  currentCart: PropTypes.object.isRequired,
  setCurrentCart: PropTypes.func.isRequired,
  squareItems: PropTypes.array.isRequired,
  upSellItems: PropTypes.array.isRequired,
  setUpSellItems: PropTypes.func.isRequired,
}

const SquareItemSelection = ({
  currentCart,
  setCurrentCart,
  squareItems,
  upSellItems,
  setUpSellItems
}) => {

  const { webstore } = useCheckout()
  const [
    waitingForIncrementOrDecrementResponse,
    setWaitingForIncrementOrDecrementResponse,
  ] = useState(false)

  const decrementSquareItem = (item) => {
    setWaitingForIncrementOrDecrementResponse(true)
    api.decrementSquareItem({
      webstore,
      cartToken: currentCart.token,
      item,
      quantity: 1
    }).then((response) => {
      setCurrentCart(response)
      setUpSellItems(response.squareLineItems)
    })
    .catch((error) => console.log(error))
    .finally(() => setWaitingForIncrementOrDecrementResponse(false))
  }

  const incrementSquareItem = (item) => {
    api.incrementSquareItem({
      webstore,
      cartToken: currentCart.token,
      item,
      quantity: 1
    }).then((response) => {
      setCurrentCart(response)
      setUpSellItems(response.squareLineItems)
    })
    .catch((error) => console.log(error))
    .finally(() => setWaitingForIncrementOrDecrementResponse(false))
  }

  /* useEffect(() => {
    const ticketValues = Object.values(ticketQuantities) || []
    const totalTicketCount = ticketValues.reduce((a, b) => a + b, 0)
    totalTicketCount >= maxTickets || waitingForIncrementOrDecrementResponse
      ? setIncrementDisabled(true)
      : setIncrementDisabled(false)
  }, [ticketQuantities])

  function decrementDisabled(item) {
    return ticketQuantities[ticketId] > 0 &&
      !waitingForIncrementOrDecrementResponse
      ? false
      : true
  } */
  
  return (
    <div>
      <h2>Would you like to add a combo?</h2>
      <div className="checkout-container">
        <div className="detail-section cruise-details square-items-wrapper">
          <div className="item-list">
            {squareItems.length > 0 ? squareItems.map((item) => (
              <div key={item.id} className="item-container">
                <div className="item-content">
                  <img src={item.itemCatalogImage} alt={item.itemName} className="item-image" />
                  <div className="item-description">
                    <h2 className="item-title">{item.itemDescription}</h2>
                    <p>${item.itemPrice / 100}</p>
                    <div className="ticket-picker">
                      <input
                        type="image"
                        name={`decrement-${item.itemVariationId}`}
                        onClick={() => decrementSquareItem(item)}
                        disabled={waitingForIncrementOrDecrementResponse}
                        src={subtractImage}
                        alt={`Remove one ${item.itemName}`}
                      />
                      <div className="ticket-number">
                        <p>{upSellItems.find(selectedItem => selectedItem.itemVariationId == item.itemVariationId)?.quantity || 0}</p> 
                      </div>
                      <input
                        type="image"
                        name={`increment-${item.itemVariationId}`}
                        disabled={waitingForIncrementOrDecrementResponse}
                        onClick={() => {
                          incrementSquareItem(item)
                        }}
                        src={addImage}
                        alt={`Add one ${item.itemName}`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )) : (
              <>
                <p>No items available</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

SquareItemSelection.propTypes = propTypes
export default SquareItemSelection
