import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { SubmitButton } from '@launchpadlab/lp-components'
import { Input } from 'components'
import PropTypes from 'prop-types'
import {
  emailValidation,
  postalCodeValidation,
  phoneValidation,
} from 'utils'

const propTypes = {
  initialValues: PropTypes.object.isRequired,
  saveMember: PropTypes.func.isRequired,
}

const defaultProps = {}

const MemberSchema = (values, props) =>
  Yup.object().shape({
    firstName: Yup.string().required('Required').trim(),
    lastName: Yup.string().required('Required').trim(),
    phone: Yup.string()
      .trim()
      .test('validation-phone', function (phone) {
        if (props.initialValues.primary) {
          const validation = phoneValidation(phone)
          if (!validation.isValid)
            return this.createError({
              path: this.path,
              message: validation.errorMessage,
            })
        }
        return true
      }),
    postalCode: Yup.string()
      .trim()
      .test('validation-postal-code', function (postalCode) {
        if (!props.initialValues.primary) {
          const validation = postalCodeValidation(postalCode)
          if (!validation.isValid) {
            return this.createError({
              path: this.path,
              message: validation.errorMessage,
            })
          }
        }
        return true
      }),
    email: Yup.string().email('Invalid email').required('Required').trim(),
  })

const MemberForm = ({ initialValues, saveMember }) => {
  // Values should be empty string instead of null. Null causes both console and breaking errors
  const sanitizedInitialValues = {
    ...initialValues,
    postalCode: initialValues.postalCode || '',
    phone: initialValues.phone || '',
  }
  const isPrimary = initialValues.primary
  return (
    <Formik
      initialValues={sanitizedInitialValues}
      validationSchema={(values) =>
        MemberSchema(values, { initialValues: sanitizedInitialValues })
      }
      onSubmit={saveMember}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form id="member-form" noValidate>
          <div className="input-field">
            <Input required name="firstName" pattern=".*\S+.*" />
          </div>
          <div className="input-field">
            <Input required name="lastName" pattern=".*\S+.*" />
          </div>
          {!isPrimary && (
            <div className="input-field">
              <Input required name="postalCode" pattern=".*\S+.*" />
            </div>
          )}
          <div className="input-field">
            <Input
              name="phone"
              label={`Phone ${!isPrimary ? '(Optional)' : ''}`}
              pattern=".*\S+.*"
              required={isPrimary}
            />
          </div>
          <div className="input-field">
            <Input required name="email" />
          </div>
          <SubmitButton submitting={isSubmitting}>Save</SubmitButton>
        </Form>
      )}
    </Formik>
  )
}

MemberForm.propTypes = propTypes
MemberForm.defaultProps = defaultProps

export default MemberForm
