import React from 'react'
import PropTypes from 'prop-types'
import { RedemptionForm, RedemptionCruiseDetails, SeasonPassCustomerDetails } from 'components'
import { Button } from '@launchpadlab/lp-components';

const propTypes = {
    webstore: PropTypes.string,
    currentPage: PropTypes.number,
    passDetails: PropTypes.object,
    setPassDetails: PropTypes.func,
    setIsValid: PropTypes.func,
    isValid: PropTypes.bool,
    selectedCruise: PropTypes.object,
    setSelectedCruise: PropTypes.func,
    handleNavButtonClick: PropTypes.func,
    updateCartInformation: PropTypes.func,
    availableCruises: PropTypes.array.isRequired,
}

const PageOne = ({
    webstore,
    currentPage,
    passDetails,
    setPassDetails, 
    isValid,
    setIsValid, 
    selectedCruise,
    setSelectedCruise,
    handleNavButtonClick,
    updateCartInformation,
    availableCruises
 }) => {
    return (
      <>
        <RedemptionForm {...{ webstore, setPassDetails, isValid, setIsValid, updateCartInformation }} />
        {isValid && (
          <>
            <SeasonPassCustomerDetails {...{ passDetails, currentPage, selectedCruise, handleNavButtonClick }} />
            <RedemptionCruiseDetails {...{ passDetails, availableCruises, selectedCruise, setSelectedCruise }} />
            {selectedCruise &&
              <Button
                style="primary"
                className="button-primary submit-order-button"
                onClick={() => handleNavButtonClick(2)}
              >
                Continue
              </Button>
            }
          </>
        )}
      </>
    )
}

PageOne.propTypes = propTypes;
const SeasonPassRedemptionPageOne = React.memo(PageOne);
export default SeasonPassRedemptionPageOne;