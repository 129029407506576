import React from 'react'
import PropTypes from 'prop-types'
import { RedemptionBoardingPass, SeasonPassCustomerDetails } from 'components';
import { Button } from '@launchpadlab/lp-components';

const propTypes = {
    currentPage: PropTypes.number,
    passDetails: PropTypes.object,
    selectedCruise: PropTypes.object,
    handleNavButtonClick: PropTypes.func,
    events: PropTypes.any,
    selectedDate: PropTypes.any,
    onDateChange: PropTypes.func,
    includedDates: PropTypes.array,
    fetchEventsForDatepickerOnMonthChange: PropTypes.func,
    inCalendarLoading: PropTypes.bool,
    hasCruiseTimes: PropTypes.bool,
    premierCruiseTimes: PropTypes.array,
    selectedEventId: PropTypes.number,
    updateCartCalendarEvent: PropTypes.func,
    setShowTicketSelector: PropTypes.func,
    standardCruiseTimes: PropTypes.array,
    termsLink: PropTypes.string,
    warningText: PropTypes.string,
    submitOrder: PropTypes.func
}


const PageTwo = ({
    currentPage,
    passDetails,
    selectedCruise,
    handleNavButtonClick,
    events,
    selectedDate,
    onDateChange,
    includedDates,
    fetchEventsForDatepickerOnMonthChange,
    inCalendarLoading,
    hasCruiseTimes,
    premierCruiseTimes,
    selectedEventId,
    updateCartCalendarEvent,
    setShowTicketSelector,
    standardCruiseTimes,
    termsLink,
    warningText,
    submitOrder
 }) => {
    return (
      <>
        <SeasonPassCustomerDetails {...{ passDetails, currentPage, selectedCruise, handleNavButtonClick }} />
        <RedemptionBoardingPass
          {...{
            events,
            selectedDate,
            onDateChange,
            includedDates,
            fetchEventsForDatepickerOnMonthChange,
            inCalendarLoading,
            hasCruiseTimes,
            premierCruiseTimes,
            selectedEventId,
            updateCartCalendarEvent,
            setShowTicketSelector,
            standardCruiseTimes,
            termsLink,
            warningText
          }}
        />
        <div>
          {selectedEventId &&
            <Button
              style="primary"
              className="button-primary submit-order-button"
              onClick={() => submitOrder()}
            >
              Submit Order
            </Button>
          }
        </div>
      </>
    )
}


PageTwo.propTypes = propTypes;
const SeasonPassRedemptionPageTwo = React.memo(PageTwo);
export default SeasonPassRedemptionPageTwo;