import { configureApi } from '@launchpadlab/lp-requests'

const api = configureApi({
  root: '/api/v1',
  successDataPath: 'data.attributes',
})

const externalApi = configureApi({
  root: '/api/v1',
})

export function fetchEvents({
  webstore,
  eventTypeId,
  startDate,
  endDate,
  ...options
}) {
  return api.get(`/webstores/${webstore}/events`, {
    query: { eventTypeId, startDate, endDate, ...options, skipCache: true },
  })
}

export function fetchEvent({ webstore, eventId, ...options }) {
  return api.get(`/webstores/${webstore}/events/${eventId}`, {
    query: { ...options, skipCache: true },
  })
}

export const fetchCart = ({ cartToken }) => {
  return api.get(`/carts/${cartToken}`)
}

export function updateCart({ webstore, cartToken, ...cart }) {
  return api.patch(`/webstores/${webstore}/carts/${cartToken}`, {
    cart,
  })
}

export const incrementTicket = ({ webstore, cartToken, ticket }) => {
  const sanitizedTicket = {
    id: ticket.id,
    ticket_type: ticket.ticketType,
    pass_type: ticket.passType,
    quantity: ticket.quantity,
  }
  return api.post(
    `/webstores/${webstore}/carts/${cartToken}/line_items/increment`,
    {
      lineItem: sanitizedTicket,
    }
  )
}

export const decrementTicket = ({ webstore, cartToken, webProductId, quantity }) => {
  return api.post(
    `/webstores/${webstore}/carts/${cartToken}/line_items/decrement`,
    {
      lineItem: { 
        id: webProductId,
        ...(quantity && { quantity: quantity })
      },
    }
  )
}

export const incrementSquareItem = ({ webstore, cartToken, item, quantity }) => {
  const squareItem = {
    item_id: item.itemId,
    variation_id: item.itemVariationId,
    quantity: quantity
  }
  return api.post(
    `/webstores/${webstore}/carts/${cartToken}/square_line_items/increment`,
    {
      squareLineItem: squareItem,
    }
  )
}

export const decrementSquareItem= ({ webstore, cartToken, item, quantity }) => {
  return api.post(
    `/webstores/${webstore}/carts/${cartToken}/square_line_items/decrement`,
    {
      squareLineItem: { 
        item_id: item.itemId,
        variation_id: item.itemVariationId,
        ...(quantity && { quantity: quantity })
      },
    }
  )
}


export const validateCoupon = ({ webstore, cartToken, couponCode }) => {
  return api.post(`/webstores/${webstore}/coupons/validate`, {
    cartToken,
    couponCode,
  })
}

export const verifyRecaptcha = ({ recaptchaResponse }) => {
  return externalApi.post(`/verify`, {
    recaptchaResponse,
  })
}

export const initializeOrder = ({ webstore, ...order }) => {
  return api.post(`/webstores/${webstore}/orders/initialize`, {
    order,
  })
}

export const authorizeOrder = ({ webstore, ...order }) => {
  return api.post(`/webstores/${webstore}/orders/authorize`, {
    order,
  })
}

export const submitOrder = ({ webstore, ...order }) => {
  return api.post(`/webstores/${webstore}/orders/submit`, {
    order,
  })
}

export const trackOrderAnalytics = (webstore, orderToken) => {
  return api.post(`/webstores/${webstore}/orders/${orderToken}/track`, {}, { successDataPath: "" } )
}

export const fetchIframe = ({ type, webstore, merchantAccountId, cartId}) => {
  return api.get(`/webstores/${webstore}/freedompay${type ? `/${type}` : ''}/iframe`, {
    query: { merchantAccountId, cartId },
  })
}

export const fetchPaymentIframe = ({ webstore, merchantAccountId, cartId }) => {
  return api.get(`/webstores/${webstore}/freedompay/iframe`, {
    query: { merchantAccountId, cartId },
  })
}

export const fetchApplePayIframe = ({ webstore, merchantAccountId, cartId }) => {
  return api.get(`/webstores/${webstore}/freedompay/apple/iframe`, {
    query: { merchantAccountId, cartId },
  })
}

export const fetchGoogleWalletIframe = ({ webstore, merchantAccountId, cartId }) => {
  return api.get(`/webstores/${webstore}/freedompay/google/iframe`, {
    query: { merchantAccountId, cartId },
  })
}



export const fetchCruise = ({ webstore, cruiseId }) => {
  return api.get(`/webstores/${webstore}/cruises/${cruiseId}`)
}

// copy of incrementTicket, but with season pass flow naming
export const addMember = ({ webstore, cartToken, member }) => {
  const sanitizedMember = {
    id: member.id,
    cruise_id: member.cruiseId,
    ticket_type: member.ticketType,
    first_name: member.firstName,
    last_name: member.lastName,
    phone: member.phone,
    postal_code: member.postalCode,
    country: member.country,
    email: member.email,
    primary: member.primary,
    pass_type: member.passType
  }
  return api.post(
    `/webstores/${webstore}/carts/${cartToken}/line_items/increment`,
    {
      lineItem: sanitizedMember,
    }
  )
}

export const removeMember = ({ webstore, cartToken, lineItemId }) => {
  return api.destroy(
    `/webstores/${webstore}/carts/${cartToken}/line_items/${lineItemId}`
  )
}

export const updateMember = ({
  webstore,
  cartToken,
  id: lineItemId,
  ...lineItem
}) => {
  return api.patch(
    `/webstores/${webstore}/carts/${cartToken}/line_items/${lineItemId}`,
    {
      lineItem,
    }
  )
}

export const trackAbandonedCart = (webstore, cartId) => {
  return api.post(
    `/webstores/${webstore}/abandoned_carts`,
    {
      cartId,
    }
  )
}

export const queryTicket = (webstore, visualId) => {
  return api.get(
    `/webstores/${webstore}/ticket/${visualId}`
  )
}
