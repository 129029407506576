import React, { useState, useEffect } from 'react'
import { useCheckout } from 'hooks'
import addImage from 'add-red-dark.svg'
import subtractImage from 'subtract-red-dark.svg'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import * as googleTagManager from 'gtm'

const propTypes = {
  tickets: PropTypes.array.isRequired,
  webstore: PropTypes.string.isRequired,
  maxTickets: PropTypes.number.isRequired,
  incrementTicket: PropTypes.func.isRequired,
  decrementTicket: PropTypes.func.isRequired,
  waitingForIncrementOrDecrementResponse: PropTypes.bool,
  ticketQuantities: PropTypes.any,
  isSeasonPass: PropTypes.bool,
  currentCart: PropTypes.object.isRequired,
}

const defaultProps = {
  waitingForIncrementOrDecrementResponse: false,
  isSeasonPass: false,
}

const PassTicketPicker = ({
  tickets,
  webstore,
  maxTickets,
  incrementTicket,
  decrementTicket,
  waitingForIncrementOrDecrementResponse,
  ticketQuantities,
  isSeasonPass,
  currentCart
}) => {

  const { webstoreName } = useCheckout()
  const [incrementDisabled, setIncrementDisabled] = useState(false)

  function decrementDisabled(ticketId) {
    const ticketCount = ticketQuantities[ticketId] ?? 0
    return ticketCount <= 0 || waitingForIncrementOrDecrementResponse
  }

  useEffect(() => {
    const ticketValues = Object.values(ticketQuantities) || []
    const totalTicketCount = ticketValues.reduce((a, b) => a + b, 0)
    setIncrementDisabled(
      totalTicketCount >= maxTickets || waitingForIncrementOrDecrementResponse
    )
  }, [ticketQuantities])

  pluralize.addUncountableRule('VIP')
  pluralize.addUncountableRule('Standard')

  return (
    <React.Fragment>
      {!isSeasonPass && <hr className="boarding-pass-rule" />}
      {tickets.map((ticket) => (
        <div key={ticket.id} className="ticket-section">
          <div className="ticket-picker">
            <input
              type="image"
              aria-label={`Remove one ${ticket.displayName}`}
              name={`decrement-${ticket.id}`}
              disabled={decrementDisabled(ticket.id)}
              onClick={() => decrementTicket(ticket)}
              src={subtractImage}
            />
            <div className="ticket-number">
              <p>{ticketQuantities[ticket.id] || 0}</p>
            </div>
            <input
              type="image"
              aria-label={`Add one ${currentCart.cruiseDisplayName}`}
              name={`increment-${ticket.id}`}
              onClick={() => {
                incrementTicket(ticket)
                googleTagManager.sendAddToCartData(
                  ticket,
                  webstoreName,
                  currentCart
                )
              }
              }
              disabled={incrementDisabled}
              src={addImage}
            />
          </div>
          <div className="detail-description-line">
            <p className="boarding-pass-description">
              {pluralize(ticket.displayName, ticketQuantities[ticket.id] || 0)}
            </p>
            <p className="line-description">${parseFloat(ticket.sellPrice).toFixed(2)} / Person</p>
          </div>
        </div>
      ))}
    </React.Fragment>
  )
}

PassTicketPicker.propTypes = propTypes
PassTicketPicker.defaultProps = defaultProps

export default PassTicketPicker
