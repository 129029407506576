import React from 'react'
import { Button } from '@launchpadlab/lp-components'
import PropTypes from 'prop-types'

const propTypes = {
  subHeader: PropTypes.string.isRequired,
  eventTimes: PropTypes.array.isRequired,
  updateCartCalendarEvent: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  setEditedCruise: PropTypes.func.isRequired,
  setStandardCruiseTimes: PropTypes.func.isRequired,
  setPremierCruiseTimes: PropTypes.func.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  setCruiseButtonActive: PropTypes.func.isRequired,
}

const EventTimes = ({
  subHeader,
  eventTimes,
  updateCartCalendarEvent,
  setShowModal,
  setEditedCruise,
  setStandardCruiseTimes,
  setPremierCruiseTimes,
  setSelectedDate,
  setCruiseButtonActive,
}) => {
  return (
    <div className="time-buttons-wrapper">
      <h3>{subHeader}</h3>
      {eventTimes.map((event) => (
        <Button
          key={event.id}
          value={event.id}
          style="secondary"
          className="button time-button"
          disabled={event.soldOut}
          onClick={() => {
            updateCartCalendarEvent(event)
            setShowModal(false)
            setEditedCruise({ eventTypeId: '', startDate: '' })
            setStandardCruiseTimes([])
            setPremierCruiseTimes([])
            setSelectedDate(null)
            setCruiseButtonActive(0)
          }}
        >
          {event.displayStartTime}
          {event.soldOut && ' (Sold Out)'}
        </Button>
      ))}
    </div>
  )
}
EventTimes.propTypes = propTypes
export default EventTimes
