import * as api from 'api';
import * as Yup from 'yup';
import swal from '@sweetalert/with-react'
import { LAYOUT_TYPES, PASS_TYPES } from 'config'
import moment from 'moment-timezone'

/* NOT USED -- BEGIN -- */

function addQueryParam(key, value) {
    const url = new URL(window.location.href)
    url.searchParams.set(key, value)
    window.history.replaceState({}, '', url.toString())
}

function getQueryParam(key) {
    const url = new URL(window.location.href)
    return url.searchParams.get(key) || null
}

/* NOT USED -- END -- */

export function removeQueryParam(key) {
    const url = new URL(window.location.href)
    url.searchParams.delete(key)
    window.history.replaceState({}, '', url.toString())
}
  
export function trackAbandonedCart(webstore, cart) {
    return api.trackAbandonedCart(webstore, cart.id)
}

export const emailField = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
})

export const calculateStartDate = (cruiseStartDate, startDate) => {
    const now = new Date();
    const date = new Date(startDate);
    const cruiseDate = new Date(cruiseStartDate);
    if(!startDate && !cruiseStartDate) return cruiseStartDate;
    if(!startDate) return cruiseStartDate;
    if(!cruiseStartDate && now.getTime() < date.getTime()) return startDate;
    if(startDate && cruiseStartDate && date.getTime() > cruiseDate.getTime()){
      return startDate;
    } 
    return cruiseStartDate;
}

export const selectNewPassUtil = (webstore, cruiseId, setSelectedPass) => {
    api.fetchCruise({ webstore, cruiseId }).then(setSelectedPass)
}

export const saveAutofilledCustomerDataUtil = (webstore, cartToken, customer, setCustomer, paymentMethod) => {
    const email = document.getElementById('email').value
    const firstName = document.getElementById('firstName').value
    const lastName = document.getElementById('lastName').value
    const phone = document.getElementById('phone').value
    const addressLine1 = document.getElementById('addressLine1').value
    const addressLine2 = document.getElementById('addressLine2').value
    const city = document.getElementById("city").value
    const state = document.getElementById("state").value
    const country = document.getElementById('country').value
    const postalCode = document.getElementById('postalCode').value
    const customerInfoMissing = Object.values(customer).some(
      (value) => !!value === false
    )
    const customerInfoFormValid = document
      .getElementById('customer-form')
      .checkValidity()
    // When the customer form is filled out with Chrome autofill, the form is considered valid via .checkValidity()
    // but the values are not passed to the backend on submit. So we grab all of the input field values and pass them to
    // updateCustomerInformation() to save all of the customer info in the database when the FreedomPay form is submitted.
    if (customerInfoMissing && customerInfoFormValid) {
      updateCustomerInformationUtil(webstore, cartToken, customer, setCustomer, {
        firstName,
        lastName,
        email,
        phone,
        addressLine1,
        addressLine2,
        city,
        state,
        country,
        postalCode,
        paymentMethod
      })
    }
  }

export  const saveAutofillCardHolderDataUtil = (webstore, cartToken, cardHolder, setCardHolder) => {
    const cardHolderFirstName = document.getElementById('cardHolderFirstName').value
    const cardHolderLastName = document.getElementById('cardHolderLastName').value
    const cardHolderCountry = document.getElementById('cardHolderCountry').value
    const cardHolderPostalCode = document.getElementById('cardHolderPostalCode').value
    const cardHolderInfoMissing = Object.values(cardHolder).some(
      (value) => !!value === false
    )
    const cardHolderInfoFormValid = document
      .getElementById('card-holder-form')
      .checkValidity()
    // When the customer form is filled out with Chrome autofill, the form is considered valid via .checkValidity()
    // but the values are not passed to the backend on submit. So we grab all of the input field values and pass them to
    // updateCustomerInformation() to save all of the customer info in the database when the FreedomPay form is submitted.
    if (cardHolderInfoMissing && cardHolderInfoFormValid) {
      updateCardHolderInformationUtil(webstore, cartToken, cardHolder, setCardHolder, {
        cardHolderFirstName,
        cardHolderLastName,
        cardHolderCountry,
        cardHolderPostalCode,
      })
    }
  }

export  async function setFullAddressUtil(value, webstore, cartToken, layoutType, customer, setCustomer, showResellerCheckout) {
    const isReseller = layoutType === LAYOUT_TYPES.RESELLER;
    const addressObject = {
      state: value.state,
      city: value.city,
      addressLine1: `${value.streetNumber} ${value.streetName}`,
      ...(isReseller && showResellerCheckout ? { cardHolderCountry: value.country } : { country: value.country }),
      ...(isReseller && showResellerCheckout ? { cardHolderPostalCode: value.postalCode } : { postalCode: value.postalCode }),
    }

    setCustomer(addressObject);
    updateCustomerInformationUtil(webstore, cartToken, customer, setCustomer, addressObject);
  }

export async function updateCustomerInformationUtil(webstore, cartToken, customer, setCustomer, customerData = {}) {
    api
      .updateCart({
        webstore,
        cartToken,
        ...customerData,
      })
      .then(() => setCustomer({ ...customer, ...customerData }))
      .catch((error) => console.log(error))
  }

export async function updateCardHolderInformationUtil(webstore, cartToken, cardHolder, setCardHolder, cardHolderData = {}) {
    api
      .updateCart({
        webstore,
        cartToken,
        ...cardHolderData,
      })
      .then(() => setCardHolder({ ...cardHolder, ...cardHolderData }))
      .catch((error) => console.log(error))
  }

export const processOrder = (webstore, orderParams, devicePayload, setPendingRequest, trackOrder) => {
    // 1. initialize order (and place a hold for timed tickets)
    // 2. authorize the charge
    // 3. submit order to egalaxy api (then captures the charge)
    // 4. order complete, redirect to confirmation page
    api
      .initializeOrder({ webstore, ...orderParams, devicePayload })
      .then((response) => {
        return api.authorizeOrder({
          webstore,
          ...orderParams,
          orderToken: response.token,
        })
      })
      .then((response) => {
        return api.submitOrder({
          webstore,
          ...orderParams,
          orderToken: response.token,
        })
      })
      .then((response) => {
        return trackOrder(orderParams, response);
      })
      .then((response) => {
        return (window.location = `/${webstore}/confirmation?orderToken=${response.token}`)
      })
      .catch((error) => {
        setPendingRequest(false)
        if(error.errors.message.indexOf("not available") !== -1){
          handleError('', 'Your ticket request exceeds available capacity. Please try again with fewer tickets or select a different date or time');
        } else {
          handleError('Oops!', error.errors.message)
        } 
      })
  }

export const handleError = (
    title = 'Oops!',
    text = 'Unable to process your order.'
  ) => {
    swal({
      title: title,
      text: text,
    })
}

export const validateCouponUtil = ({ webstore, cartToken, setCurrentCart, coupon }) => {
    api
      .validateCoupon({
        webstore,
        cartToken,
        couponCode: coupon,
      })
      .then((response) => updateCartCouponUtil({webstore, cartToken, setCurrentCart, couponCode: response.code}))
      .catch(({ errors }) => {
        handleError('Oops!', errors.message)
      })
  }

export const updateCartCouponUtil = ({webstore,cartToken, setCurrentCart, couponCode = ''}) => {
    api
      .updateCart({ webstore, cartToken, coupon: couponCode })
      .then((response) => {
        setCurrentCart(response)
      })
      .catch((error) => console.log(error))
  }

export const checkoutTitle = (showResellerCheckout, layoutType) => {
    let title = ''

    if (showResellerCheckout) {
      title = 'Reseller Checkout'
    } else {
      title += 'Purchase '
      if (layoutType === LAYOUT_TYPES.RESELLER){ title += 'Reseller '}
      title += 'Tickets'
    }

    return title
  }

export const handleNavButtonClick = (value, setCurrentPage, currentCart) => {
    if(value == 2 && currentCart.cruisePassType !== "season_pass"){
      const url = new URL(window.location.href)
      url.searchParams.set("eventId", currentCart.eventId)
      url.searchParams.set("eventTypeId", currentCart.eventTypeId)
      url.searchParams.set("eventTimeId", currentCart.eventId)
      url.searchParams.set("startDate", moment.utc(currentCart.eventStart).format('YYYY-MM-DD'))
      window.history.replaceState({}, '', url.toString())
    } 
    setCurrentPage(value);
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
}

export function creditCardValue(creditCardInformation, key) {
  if (!creditCardInformation) return '';
  const keyInfo = creditCardInformation.find((x) => x.Key.toLowerCase() === key.toLowerCase());
  return keyInfo ? keyInfo.Value : '';
}

export function splitCardHolderName(cardHolderName) {
  const nameParts = cardHolderName.split(' ');
  const firstName = nameParts.shift(); 
  const lastName = nameParts.join(' '); 

  return { firstName, lastName }
}


export const validateDataForWallet = async (webstore, currentCart, setCustomer, pendingRequest, setPendingRequest, paymentKey, creditCardInformation, 
    sessionKey, paymentMethod, trackOrder) => {
  
  console.log(creditCardInformation);
  let customerData = {};
  let billingAddress = {};
  const termsAndConditions = document.getElementById('terms-checkbox')
  const recaptchaComplete =
    document.getElementById('recap-response').value === 'true'

  if (termsAndConditions && !termsAndConditions.checked) {
    handleError('Oops!', 'Please accept the terms and conditions.')
  } else if (!recaptchaComplete) {
    handleError('Oops!', 'Please complete the reCAPTCHA.')
  }  else {
    if(paymentMethod == 'google') {
      billingAddress = creditCardInformation.find(item => item.Key === "BillingAddress").Value
      const {firstName, lastName} = splitCardHolderName(billingAddress.name)
      customerData = {
        firstName: firstName,
        lastName: lastName,
        email: creditCardInformation.find(item => item.Key === "Email").Value,
        phone:  billingAddress.phoneNumber,
        addressLine1: billingAddress.address1,
        addressLine2: billingAddress.address2,      
      }
    } else if(paymentMethod == 'apple'){
      billingAddress = creditCardInformation.find(item => item.Key === "billingContact").Value 
      let shippingAddress = creditCardInformation.find(item => item.Key === "shippingContact").Value 
      billingAddress = JSON.parse(billingAddress);
      shippingAddress = JSON.parse(shippingAddress);
      customerData = {
        firstName: billingAddress.givenName,
        lastName: billingAddress.familyName,
        email: shippingAddress.emailAddress,
        phone:  shippingAddress.phoneNumber,
        addressLine1: billingAddress.addressLines.length > 0 ? billingAddress.addressLines[0] : null,
        addressLine2: billingAddress.addressLines.length > 1 ? billingAddress.addressLines[1] : null,
      }
    }
  
    customerData = {
      ...customerData,
      city: billingAddress.locality,
      state: billingAddress.administrativeArea,
      country: billingAddress.countryCode,
      postalCode: billingAddress.postalCode,
      paymentMethod: paymentMethod,
    }
    
    setCustomer(customerData)
    api.updateCart({
      webstore,
      cartToken: currentCart.token,
      ...customerData,
    }).then((response) => {
      if (!pendingRequest) {
        const postalCode = billingAddress.postalCode
        const cardNumber = creditCardValue(creditCardInformation, 'MaskedCardNumber')
        const lastFourDigits = cardNumber  ? cardNumber.slice(cardNumber.length - 4) : ''
        const cardType = creditCardValue(creditCardInformation, 'CardIssuer')
        setPendingRequest(true)
        let data = null;
        if(window.hasOwnProperty('collector') && window.hasOwnProperty('_bcn') && window._bcn.hasOwnProperty('dvc')) {
          data = JSON.stringify(collector.getPayload(_bcn.dvc.getTID()));
        }
        processOrder(webstore, {
          cartToken: currentCart.token,
          sessionKey: sessionKey,
          paymentKey: sessionKey ? paymentKey : '',
          maskedCardNumber: cardNumber,
          cardType,
          lastFourDigits,
          billingPostalCode: postalCode,
        }, data, setPendingRequest, trackOrder)
      }
    }).catch((error) => handleError('Oops!', error.errors.message))
  }
}



export default function checkoutUtil(){
  // Nothing here, just fix build error
}
