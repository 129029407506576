import React from 'react'
import { useCheckout }  from 'hooks'
import { CustomerForm, CouponForm } from 'forms'
import { CruiseDetails, Receipt, SquareItemSelection } from 'components'
import PropTypes from 'prop-types'
import { ticketQuantityMap } from 'utils'

const propTypes = {
  event: PropTypes.object,
  customer: PropTypes.object.isRequired,
  updateCustomerInformation: PropTypes.func.isRequired,
  currentCart: PropTypes.object.isRequired,
  startDate: PropTypes.string.isRequired,
  validateCoupon: PropTypes.func.isRequired,
  updateCartCoupon: PropTypes.func.isRequired,
  incrementTicket: PropTypes.func.isRequired,
  decrementTicket: PropTypes.func.isRequired,
  availableCruises: PropTypes.array.isRequired,
  editCruiseDetails: PropTypes.func.isRequired,
  setStandardCruiseTimes: PropTypes.func.isRequired,
  setPremierCruiseTimes: PropTypes.func.isRequired,
  standardCruiseTimes: PropTypes.array,
  premierCruiseTimes: PropTypes.array,
  updateCartCalendarEvent: PropTypes.func,
  webstore: PropTypes.string.isRequired,
  waitingForIncrementOrDecrementResponse: PropTypes.bool.isRequired,
  fetchEventsForDatepickerOnClick: PropTypes.func.isRequired,
  includedDates: PropTypes.array.isRequired,
  initialDatePickerLoading: PropTypes.bool.isRequired,
  changeCruise: PropTypes.func.isRequired,
  editedCruise: PropTypes.object,
  setEditedCruise: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  setSelectedDate: PropTypes.func.isRequired,
  convertDatePickerValue: PropTypes.func.isRequired,
  hasCruiseTimes: PropTypes.bool.isRequired,
  fetchEventsForDatepickerOnMonthChange: PropTypes.func.isRequired,
  datePickerLoading: PropTypes.bool.isRequired,
  setFullAddress: PropTypes.func.isRequired,
  isSinglePage: PropTypes.bool.isRequired,
  upSellItems: PropTypes.array.isRequired,
  setUpSellItems: PropTypes.func.isRequired,
  setCurrentCart: PropTypes.func.isRequired,
}

const defaultProps = {}

function TimedTicketCruiseFlow({
  event,
  customer,
  updateCustomerInformation,
  currentCart,
  startDate,
  validateCoupon,
  updateCartCoupon,
  incrementTicket,
  decrementTicket,
  availableCruises,
  editCruiseDetails,
  setStandardCruiseTimes,
  setPremierCruiseTimes,
  standardCruiseTimes,
  premierCruiseTimes,
  updateCartCalendarEvent,
  webstore,
  waitingForIncrementOrDecrementResponse,
  fetchEventsForDatepickerOnClick,
  includedDates,
  initialDatePickerLoading,
  changeCruise,
  editedCruise,
  setEditedCruise,
  selectedDate,
  setSelectedDate,
  convertDatePickerValue,
  hasCruiseTimes,
  fetchEventsForDatepickerOnMonthChange,
  datePickerLoading,
  setFullAddress,
  isSinglePage,
  upSellItems,
  setUpSellItems,
  setCurrentCart
}) {
  const { enableUpsell, squareItems } = useCheckout(); 
  const admissionLineItems = currentCart.lineItems.filter(li => !li.fee)
  return (
    <React.Fragment>
      {isSinglePage && event && (
        <CustomerForm
          initialValues={{ ...customer }}
          updateCustomerInformation={updateCustomerInformation}
          setFullAddress={setFullAddress}
        />
      )}
      <CruiseDetails
        hasEvent={!!event}
        displayName={event ? event.displayName : currentCart.cruiseDisplayName}
        location={event ? event.location : currentCart.cruiseLocation}
        date={event ? event.startDate : startDate}
        displayStartTime={event ? event.displayStartTime : ''}
        cruiseType={event ? event.cruiseType : ''}
        durationTimeDisplay={
          event
            ? event.durationTimeDisplay
            : currentCart.cruiseDurationTimeDisplay
        }
        icon={currentCart.cruiseImage}
        maxTickets={currentCart.maxTickets}
        tickets={event ? event.tickets : []}
        ticketQuantities={ticketQuantityMap(admissionLineItems)}
        incrementTicket={incrementTicket}
        decrementTicket={decrementTicket}
        availableCruises={availableCruises}
        editCruiseDetails={editCruiseDetails}
        setStandardCruiseTimes={setStandardCruiseTimes}
        standardCruiseTimes={standardCruiseTimes}
        setPremierCruiseTimes={setPremierCruiseTimes}
        premierCruiseTimes={premierCruiseTimes}
        updateCartCalendarEvent={updateCartCalendarEvent}
        webstore={webstore}
        waitingForIncrementOrDecrementResponse={
          waitingForIncrementOrDecrementResponse
        }
        initialDatePickerLoading={initialDatePickerLoading}
        includedDates={includedDates}
        fetchEventsForDatepickerOnClick={fetchEventsForDatepickerOnClick}
        changeCruise={changeCruise}
        editedCruise={editedCruise}
        setEditedCruise={setEditedCruise}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        convertDatePickerValue={convertDatePickerValue}
        hasCruiseTimes={hasCruiseTimes}
        fetchEventsForDatepickerOnMonthChange={
          fetchEventsForDatepickerOnMonthChange
        }
        datePickerLoading={datePickerLoading}
        currentCart={currentCart}
      />
      {event && <CouponForm onSubmit={validateCoupon} />}
      {enableUpsell && currentCart.lineItems.length > 0 && (
        <SquareItemSelection
          {...{
            currentCart,
            setCurrentCart,
            squareItems,
            upSellItems,
            setUpSellItems,
          }}
        />
      )}
      <Receipt
        lineItems={currentCart.lineItems}
        taxes={currentCart.taxes}
        discounts={currentCart.discounts}
        couponDetails={currentCart.couponDetails}
        removeCoupon={() => updateCartCoupon('')}
        currentCart={currentCart}
        total={currentCart.total}
        cruiseDisplayName={
          event ? event.displayName : currentCart.cruiseDisplayName
        }
        startDateTime={event ? event.startDateTime : currentCart.startDateTime}
      />
    </React.Fragment>
  )
}

TimedTicketCruiseFlow.propTypes = propTypes
TimedTicketCruiseFlow.defaultProps = defaultProps

export default React.memo(TimedTicketCruiseFlow)
