import React, { useState, useEffect } from 'react'
import { useCheckout }  from 'hooks'
import { PaymentForm } from 'forms'
import {
  TermsAndConditions,
  SubmitOrder,
  SeasonPass,
  TimedTicket,
  PassTypeSelector,
} from 'components'
import * as api from 'api'
import sslCheckoutImage from 'sslcheckout.svg'
import * as Yup from 'yup'
import swal from '@sweetalert/with-react'
import { LAYOUT_TYPES, PASS_TYPES } from 'config'
import { emailValidation, postalCodeValidation, phoneValidation } from 'utils';
import { useNinetailed } from '@ninetailed/experience.js-react';

const emailField = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
})

function addQueryParam(key, value) {
  const url = new URL(window.location.href)
  url.searchParams.set(key, value)
  window.history.replaceState({}, '', url.toString())
}

function removeQueryParam(key) {
  const url = new URL(window.location.href)
  url.searchParams.delete(key)
  window.history.replaceState({}, '', url.toString())
}

function getQueryParam(key) {
  const url = new URL(window.location.href)
  return url.searchParams.get(key) || null
}

const UnavailableSelectionsMessage = () => (
  <div>
    <p style={{color: 'rgba(0,0,0,.64)'}}>
      Looks like the cruise you were looking at has sold out. <a href="https://circleline.com/sightseeing-cruises" style={{ textDecoration: 'underline', color: 'rgba(0,0,0,.64)' }}>Click here</a> to check out other options!
    </p>
  </div>
)

const calculateStartDate = (cruiseStartDate, startDate) => {
  const now = new Date();
  const date = new Date(startDate);
  const cruiseDate = new Date(cruiseStartDate);
  if(!startDate && !cruiseStartDate) return cruiseStartDate;
  if(!startDate) return cruiseStartDate;
  if(!cruiseStartDate && now.getTime() < date.getTime()) return startDate;
  if(startDate && cruiseStartDate && date.getTime() > cruiseDate.getTime()){
    return startDate;
  } 
  return cruiseStartDate;
}

const Checkout = () => {

  const {
    webstore,
    eventId,
    eventTypeId,
    startDate,
    cart,
    availableCruises,
    termsLink,
    warningText,
    layoutType,
    user,
    abandonedCartEnabled,
  } = useCheckout();

  const { track } = useNinetailed();

  function trackAbandonedCart() {
    return api.trackAbandonedCart(webstore, cart.id)
  }

  useEffect(() => {
    // addQueryParam('cart_token', cart.token)
    removeQueryParam('cart_token');

    // set ENV HUBSPOT_ABANDONED_CART_ENABLED to true
    // once NYCL is ready to enable abandoned cart on production
    if (!abandonedCartEnabled) return

    if (cart.previousSelectionsUnavailable) {
      swal({
        title: "Oh no!",
        content: <UnavailableSelectionsMessage/>,
      })
    }

    window.addEventListener('beforeunload', trackAbandonedCart)

    return () => {
      window.removeEventListener('beforeunload', trackAbandonedCart)
    }
  }, [])

  const [currentCart, setCurrentCart] = useState(cart)

  const { token: cartToken, merchantAccountId } = currentCart
  const [customer, setCustomer] = useState({
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    email: user.email || '',
    phone: '',
    country: '',
    postalCode: '',
    addressLine1: '',
    addressLine2: '',
    state: '',
    city: '',
    cardHolderFirstName: user.firstName || '',
    cardHolderLastName: user.lastName || '',
    cardHolderCountry: '',
    cardHolderPostalCode: '',
  })
  const [cardHolder, setCardHolder] = useState({
    cardHolderFirstName: user.firstName || '',
    cardHolderLastName: user.lastName || '',
    cardHolderCountry: '',
    cardHolderPostalCode: '',
  })
  const hasItems = currentCart.lineItems.length > 0
  const showPaymentForm = (hasItems && currentCart.total > 0)
  const freeOrder = hasItems && currentCart.total === 0
  const [pendingRequest, setPendingRequest] = useState(false)
  const [upSellItems, setUpSellItems] = useState([]);

  const [showResellerCheckout, setShowResellerCheckout] = useState(false)

  const [selectedPass, setSelectedPass] = useState({
    eventTypeId,
    id: currentCart.cruiseId,
    passType: currentCart.cruisePassType,
    defaultStartDate: calculateStartDate(currentCart.cruiseStartDate, startDate)
  })
  const isSeasonPass = selectedPass.passType === PASS_TYPES.SEASON_PASS

  const selectNewPass = (cruiseId) => {
    api.fetchCruise({ webstore, cruiseId }).then(setSelectedPass)
  }

  function creditCardValue(creditCardInformation, key) {
    if (!creditCardInformation) return ''
    const keyInfo = creditCardInformation.find((x) => x.Key === key)
    return keyInfo ? keyInfo.Value : ''
  }

  const validateRequiredData = (
    paymentKey,
    creditCardInformation = [],
    sessionKey
  ) => {
    if (!isSeasonPass && !showResellerCheckout) {
      // Customer Form Validity
      const customerFormValid = document
        .getElementById('customer-form')
        .checkValidity()

      if (!customerFormValid) {
        handleError(
          'Oops! Missing Customer Information',
          'Please complete the customer information section.'
        )
      }
      
      saveAutofilledCustomerData();
    }

    if(layoutType === 'reseller'){
      saveAutofillCardHolderData();
      // Card Holder Form Validity
      const cardHolderFormValidity = document
      .getElementById("card-holder-form")
      .checkValidity();
      if (!cardHolderFormValidity) {
        handleError(
          'Oops! Missing Card Holder Information',
          'Please complete the card holder information section.'
        )
        return;
      }
    }

    const termsAndConditions = document.getElementById('terms-checkbox')
    const recaptchaComplete =
      document.getElementById('recap-response').value === 'true'

    if (termsAndConditions && !termsAndConditions.checked) {
      handleError('Oops!', 'Please accept the terms and conditions.')
    } else if (!recaptchaComplete) {
      handleError('Oops!', 'Please complete the reCAPTCHA.')
    } else {
      if (!pendingRequest) {
        let isEverythingValid = false;
        if(!isSeasonPass && !showResellerCheckout){
          const email = document.getElementById('customer-form').email.value
          const phone = document.getElementById('customer-form').phone.value
          const addressLine1 = document.getElementById('customer-form').addressLine1.value
          const city = document.getElementById('customer-form').city.value
          const state = document.getElementById('customer-form').state.value
          const postalCodeValue = document.getElementById('customer-form').postalCode.value
    
          const isEmailValid = emailValidation(email);
          const isPhoneValid = phoneValidation(phone);
          const isPostalCodeValid = postalCodeValidation(postalCodeValue);

          if(!(isEmailValid.isValid && isPhoneValid.isValid && isPostalCodeValid.isValid && addressLine1 && city && state)){
            handleError(
              'Oops!',
              'Please provide a valid information in customer information section.'
            )
          } else {
            isEverythingValid = true
          }
        } else if(isSeasonPass) {
          const addressLine1 = document.getElementById('address-form').addressLine1.value
          const city = document.getElementById('address-form').city.value
          const state = document.getElementById('address-form').state.value
          const postalCodeValue = document.getElementById('address-form').postalCode.value

          const isPostalCodeValid = postalCodeValidation(postalCodeValue);
          if(!(isPostalCodeValid.isValid && addressLine1 && city && state)){
            handleError(
              'Oops!',
              'Please provide a valid information in address details section.'
            )
          } else {
            isEverythingValid = true
          }
        } else {
          isEverythingValid = true
        }
  
        if(isEverythingValid){
          const postalCode = creditCardValue(creditCardInformation, 'PostalCode')
          const cardNumber = creditCardValue(
            creditCardInformation,
            'MaskedCardNumber'
          )
          const expirationDate = creditCardValue(creditCardInformation, 'ExpirationDate')
          const lastFourDigits = cardNumber
            ? cardNumber.slice(cardNumber.length - 4)
            : ''
          const cardType = creditCardValue(creditCardInformation, 'CardIssuer')
          setPendingRequest(true)
          let data = null;
          if(window.hasOwnProperty('collector') && window.hasOwnProperty('_bcn') && window._bcn.hasOwnProperty('dvc')) {
            data = JSON.stringify(collector.getPayload(_bcn.dvc.getTID()));
          }
          processOrder({
            cartToken: currentCart.token,
            sessionKey: sessionKey,
            paymentKey: sessionKey ? paymentKey : '',
            maskedCardNumber: cardNumber,
            cardType,
            lastFourDigits,
            expirationDate: expirationDate ? expirationDate.replace("/","") : '',
            billingPostalCode: postalCode,
          }, data)
        }
      }
    }
  }

  const saveAutofilledCustomerData = () => {
    const email = document.getElementById('email').value
    const firstName = document.getElementById('firstName').value
    const lastName = document.getElementById('lastName').value
    const phone = document.getElementById('phone').value
    const addressLine1 = document.getElementById('addressLine1').value
    const addressLine2 = document.getElementById('addressLine2').value
    const city = document.getElementById("city").value
    const state = document.getElementById("state").value
    const country = document.getElementById('country').value
    const postalCode = document.getElementById('postalCode').value
    const customerInfoMissing = Object.values(customer).some(
      (value) => !!value === false
    )
    const customerInfoFormValid = document
      .getElementById('customer-form')
      .checkValidity()
    // When the customer form is filled out with Chrome autofill, the form is considered valid via .checkValidity()
    // but the values are not passed to the backend on submit. So we grab all of the input field values and pass them to
    // updateCustomerInformation() to save all of the customer info in the database when the FreedomPay form is submitted.
    if (customerInfoMissing && customerInfoFormValid) {
      updateCustomerInformation({
        firstName,
        lastName,
        email,
        phone,
        addressLine1,
        addressLine2,
        city,
        state,
        country,
        postalCode,
      })
    }
  }

  const saveAutofillCardHolderData = () => {
    const cardHolderFirstName = document.getElementById('cardHolderFirstName').value
    const cardHolderLastName = document.getElementById('cardHolderLastName').value
    const cardHolderCountry = document.getElementById('cardHolderCountry').value
    const cardHolderPostalCode = document.getElementById('cardHolderPostalCode').value
    const cardHolderInfoMissing = Object.values(cardHolder).some(
      (value) => !!value === false
    )
    const cardHolderInfoFormValid = document
      .getElementById('card-holder-form')
      .checkValidity()
    // When the customer form is filled out with Chrome autofill, the form is considered valid via .checkValidity()
    // but the values are not passed to the backend on submit. So we grab all of the input field values and pass them to
    // updateCustomerInformation() to save all of the customer info in the database when the FreedomPay form is submitted.
    if (cardHolderInfoMissing && cardHolderInfoFormValid) {
      updateCardHolderInformation({
        cardHolderFirstName,
        cardHolderLastName,
        cardHolderCountry,
        cardHolderPostalCode,
      })
    }
  }

  async function setFullAddress(value) {
    const isReseller = layoutType === LAYOUT_TYPES.RESELLER;
    const addressObject = {
      state: value.state,
      city: value.city,
      addressLine1: `${value.streetNumber} ${value.streetName}`,
      ...(isReseller && showResellerCheckout ? { cardHolderCountry: value.country } : { country: value.country }),
      ...(isReseller && showResellerCheckout ? { cardHolderPostalCode: value.postalCode } : { postalCode: value.postalCode }),
    }

    setCustomer(addressObject);
    updateCustomerInformation(addressObject);
  }

  async function updateCustomerInformation(customerData = {}) {
    api
      .updateCart({
        webstore,
        cartToken,
        ...customerData,
      })
      .then(() => setCustomer({ ...customer, ...customerData }))
      .catch((error) => console.log(error))
  }

  async function updateCardHolderInformation(cardHolderData = {}) {
    api
      .updateCart({
        webstore,
        cartToken,
        ...cardHolderData,
      })
      .then(() => setCardHolder({ ...cardHolder, ...cardHolderData }))
      .catch((error) => console.log(error))
  }

  async function trackOrder(orderParams, response) {
    await track('completed_purchase', {
      cartToken: orderParams.cartToken,
      cruiseDisplayName: response?.cart?.cruiseDisplayName,
      purchaseTotal: response?.cart?.total
    });
    return response;
  }

  const processOrder = (orderParams, devicePayload) => {
    // 1. initialize order (and place a hold for timed tickets)
    // 2. authorize the charge
    // 3. submit order to egalaxy api (then captures the charge)
    // 4. track order in ninetailed
    // 5. order complete, redirect to confirmation page
    api
      .initializeOrder({ webstore, ...orderParams, devicePayload })
      .then((response) => {
        return api.authorizeOrder({
          webstore,
          ...orderParams,
          orderToken: response.token,
        })
      })
      .then((response) => {
        return api.submitOrder({
          webstore,
          ...orderParams,
          orderToken: response.token,
        })
      })
      .then((response) => {
        return trackOrder(orderParams, response);
      })
      .then((response) => {  
        return (window.location = `/${webstore}/confirmation?orderToken=${response.token}`)
      })
      .catch((error) => {
        setPendingRequest(false)
        if(error.errors.message.indexOf("not available") !== -1){
          handleError('', 'Your ticket request exceeds available capacity. Please try again with fewer tickets or select a different date or time');
        } else {
          handleError('Oops!', error.errors.message)
        } 
      })
  }

  const handleError = (
    title = 'Oops!',
    text = 'Unable to process your order.'
  ) => {
    swal({
      title: title,
      text: text,
    })
  }

  const validateCoupon = ({ coupon }) => {
    api
      .validateCoupon({
        webstore,
        cartToken,
        couponCode: coupon,
      })
      .then((response) => updateCartCoupon(response.code))
      .catch(({ errors }) => {
        handleError('Oops!', errors.message)
      })
  }

  const updateCartCoupon = (couponCode = '') => {
    api
      .updateCart({ webstore, cartToken, coupon: couponCode })
      .then((response) => {
        setCurrentCart(response)
      })
      .catch((error) => console.log(error))
  }

  const checkoutTitle = () => {
    let title = ''

    if (showResellerCheckout) {
      title = 'Reseller Checkout'
    } else {
      title += 'Purchase '
      if (layoutType === LAYOUT_TYPES.RESELLER){ title += 'Reseller '}
      title += 'Tickets'
    }

    return title
  }

  return (
    <div>
      <div className="checkout-header">
        <h1>
          {checkoutTitle()}
        </h1>
        <img src={sslCheckoutImage} alt="SSL secure checkout" />
      </div>

      {layoutType === LAYOUT_TYPES.PASS && (
        <PassTypeSelector
          availableCruises={availableCruises}
          selectedPass={selectedPass}
          selectNewPass={selectNewPass}
        />
      )}

      {isSeasonPass ? (
        <SeasonPass
          {...{
            webstore,
            currentCart,
            setCurrentCart,
            selectedPass,
            updateCartCoupon,
            validateCoupon,
            selectNewPass,
            updateCustomerInformation,
            setFullAddress,
          }}
          initialValues={{ ...customer }}
        />
      ) : (
        <TimedTicket
          {...{
            layoutType,
            webstore,
            currentCart,
            setCurrentCart,
            customer,
            updateCustomerInformation,
            updateCardHolderInformation,
            availableCruises,
            updateCartCoupon,
            validateCoupon,
            eventId,
            startDate,
            eventTypeId,
            selectedPass,
            showResellerCheckout,
            setShowResellerCheckout,
            handleError,
            pendingRequest,
            setPendingRequest,
            termsLink,
            saveAutofilledCustomerData,
            hasItems,
            validateRequiredData,
            emailField,
            selectNewPass,
            setFullAddress,
            isSinglePage: true,
            upSellItems,
            setUpSellItems
          }}
        />
      )}
      {showPaymentForm && layoutType != LAYOUT_TYPES.RESELLER && (
        <PaymentForm
          webstore={webstore}
          fetchPaymentIframe={() =>
            api.fetchPaymentIframe({ webstore, merchantAccountId,cartId: currentCart.id })
          }
          validateRequiredData={validateRequiredData}
          handleError={handleError}
          pendingRequest={pendingRequest}
          currentCart={currentCart}
        />
      )}

      {hasItems && layoutType != LAYOUT_TYPES.RESELLER && (
        <TermsAndConditions
          termsLink={termsLink}
          verifyRecaptcha={api.verifyRecaptcha}
          warningText={warningText}
        />
      )}

      {freeOrder && (
        <SubmitOrder
          onSubmit={validateRequiredData}
          pendingRequest={pendingRequest}
        />
      )}
    </div>
  )
}

export default Checkout