import React, { useState, useEffect, useCallback } from 'react'
import { useCheckout }  from 'hooks'
import PropTypes from 'prop-types'
import { Spinner } from '@launchpadlab/lp-components'
import creditCardLogos from 'credit_card.png'
import * as googleTagManager from 'gtm'
import * as api from 'api'

const propTypes = {
  validateRequiredData: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  pendingRequest: PropTypes.bool.isRequired,
  currentCart: PropTypes.object.isRequired,
}

const PaymentForm = ({
  validateRequiredData,
  handleError,
  pendingRequest,
  currentCart,
}) => {

  const {
    webstore,
  } = useCheckout();


  const [sessionKey, setSessionKey] = useState('')
  const [iframeHtml, setIframeHtml] = useState('')

  const handleIframeSubmit = useCallback(
    (e) => {
      if (sessionKey) {
        const message = e.data
        const data = message.data

        switch (message.type) {
          case 1:
            data.errors
            console.log('case1', data.errors)
            const hasSessionKeyError = data.errors.filter((err) =>
              err.message.toLowerCase().includes("'sessionkey' is invalid")
            )

            if (hasSessionKeyError) {
              // if there is a sessionKey invalid error, reset sessionkey
              // to force payment form to reload with new iframe and sessionKey
              setSessionKey('')
            } else {
              handleError('Payment Processing Error', data.errors[0].message)
            }
            break
          case 2:
            data.height
            console.log('case2')
            if (document.getElementsByTagName('iframe')[0])
              document.getElementsByTagName('iframe')[0].height = data.height
            break
          case 3:
            data.attributes
            console.log('case3', data.attributes)
            const paymentKey = data.paymentKeys[0]
            validateRequiredData(paymentKey, data.attributes, sessionKey, 'card')
            break
          case 4:
            data.message
            console.log('case4', data.message)
            if (
              data.isValid &&
              sessionStorage.getItem('checkoutDataSent') !== 'true'
            ) {
              googleTagManager.sendCheckoutData(currentCart)
              sessionStorage.setItem('checkoutDataSent', true)
            }
            break
        }
      }
    },
    [sessionKey]
  )

  useEffect(() => {
    if (!sessionKey) {
      api.fetchPaymentIframe({webstore, merchantAccountId: currentCart.merchantAccountId,cartId: currentCart.id}).then((response) => {
        setIframeHtml(response.html)
        setSessionKey(response.sessionKey)
      })
    }
  }, [sessionKey])

  useEffect(() => {
    window.addEventListener('message', handleIframeSubmit)
    return () => window.removeEventListener('message', handleIframeSubmit)
  }, [handleIframeSubmit])

  if (!iframeHtml) return <Spinner />

  return (
    <div>
      <div className="payment-header">
        <h2>Card Details</h2>
      </div>
      <div
        id="payment-section"
        className={
          pendingRequest
            ? 'pending-request-spinner freedompay-section'
            : 'freedompay-section'
        }
      >
        {pendingRequest ? (
          <Spinner />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: iframeHtml }} />
        )}
      </div>
    </div>
  )
}

PaymentForm.propTypes = propTypes

export default PaymentForm
